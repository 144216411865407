import {
  Modal,
  Backdrop,
  Fade,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  TextField,
} from "@material-ui/core";
import {
  DropdownSelectorOption,
  ITherapist,
  ModalProps,
} from "../../common/interfaces";
import clsx from "clsx";
import React, { ChangeEvent } from "react";
import "react-toastify/dist/ReactToastify.css";
import ConfirmationModal from "../ConfirmationModal";
import DropdownSelect from "../Input/DropdownSelect";
import { ServicesContext } from "../../context/ServicesContext";
import { ConfirmDenyButtonAction } from "../Input/ConfirmDenyButtonAction";
import { SchoolSelectionTable } from "../IndividualStaffView/SchoolSelectionTable";
import Column from "../Display/Column";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalContentArea: {
      maxHeight: "95%",
      overflowY: "auto",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "10px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    inlineContainer: {
      display: "inline",
    },
    centeringContainer: {
      display: "block",
      margin: "auto",
      padding: theme.spacing(2, 4, 3),
    },
    defaultMessage: {
      fontSize: 18,
      textAlign: "center",
    },
    button: {
      margin: "10px",
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    flexRow: {
      display: "flex",
      flexDirection: "row",
    },
    table: {
      padding: "0",
    },
    label: {
      textAlign: "center",
      fontSize: "12",
      width: "100%",
      margin: "auto",
    },
    error: {
      border: "1px red solid",
    },
    scrollable: {
      overflowY: "auto",
    },
    fixedMaxHieght: {
      maxHeight: "200px",
    },
    input: {},
  })
);

interface StaffEditModalProps extends ModalProps {
  therapist: ITherapist | undefined;
  handleExit: () => void;
  handleSave: (newTherapist: ITherapist) => void;
}

export default function StaffEditModal(
  props: StaffEditModalProps
): JSX.Element {
  const { open, setOpen, therapist, handleExit, handleSave } = props;
  const { classes } = React.useContext(ServicesContext);
  const styles = useStyles();
  const [cancelConfirmOpen, setCancelConfirmOpen] =
    React.useState<boolean>(false);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  const [nameError, setNameError] = React.useState<boolean>(false);
  const [userIdError, setUserIdError] = React.useState<boolean>(false);
  const [classError, setClassError] = React.useState<boolean>(false);
  const [gradeError, setGradeError] = React.useState<boolean>(false);

  const creatingNew = therapist === undefined;
  const header = creatingNew ? "Create Therapist" : `Edit ${therapist?.name}`;

  const makeNewTherapist = () => {
    return {
      name: "",
      schoolIds: [],
      classId: "",
      gradeId: "",
      userId: "",
    } as unknown as ITherapist;
  };

  const [editingTherapist, setEditingTherapist] =
    React.useState<ITherapist>(makeNewTherapist());

  React.useEffect(() => {
    setEditingTherapist(therapist ?? makeNewTherapist());
  }, [therapist]);

  const handleNameChange = React.useCallback((incoming: string) => {
    setIsEditing(true);
    setEditingTherapist((prev) => ({ ...prev, name: incoming }));
  }, []);

  const handleClassChange = React.useCallback((incoming: string) => {
    setIsEditing(true);

    setEditingTherapist((prev) => ({
      ...prev,
      classId: incoming,
      gradeId: "",
    }));
  }, []);

  const handleGradeChange = React.useCallback((incoming: string) => {
    setIsEditing(true);
    setEditingTherapist((prev) => ({ ...prev, gradeId: incoming }));
  }, []);

  const handleAddSchool = React.useCallback((incoming: string) => {
    setIsEditing(true);
    setEditingTherapist((prev) => ({
      ...prev,
      schoolIds: [...prev.schoolIds, incoming],
    }));
  }, []);

  const handleRemoveSchool = React.useCallback((incoming: string) => {
    setIsEditing(true);
    setEditingTherapist((prev) => ({
      ...prev,
      schoolIds: [...prev.schoolIds.filter((id) => id !== incoming)],
    }));
  }, []);

  const handleUserIdChange = React.useCallback((incoming: string) => {
    setIsEditing(true);
    setEditingTherapist((prev) => ({
      ...prev,
      userId: incoming.replace("auth0|", ""),
    }));
  }, []);

  const saveOrCreate = React.useCallback(() => {
    let hasError = false;
    if (editingTherapist.name.length === 0) {
      setNameError(true);
      hasError = true;
    }
    if (editingTherapist.userId.length === 0) {
      setUserIdError(true);
      hasError = true;
    }
    if (editingTherapist.classId.length === 0) {
      setClassError(true);
      hasError = true;
    }
    if (editingTherapist.gradeId.length === 0) {
      setGradeError(true);
      hasError = true;
    }

    if (hasError) return;

    handleSave(editingTherapist);
    setIsEditing(false);
    setEditingTherapist(makeNewTherapist());
  }, [editingTherapist, handleSave]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={clsx(styles.paper)} style={{ width: "40vw" }}>
            <div>
              <Column gap="m">
                <Typography variant="h6">{header}</Typography>
                <TextField
                  className={styles.input}
                  label="Name"
                  variant="outlined"
                  value={editingTherapist.name}
                  onChange={(
                    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setNameError(false);
                    handleNameChange(e.target.value);
                  }}
                  error={nameError}
                  required
                />
                <TextField
                  className={styles.input}
                  label="User ID"
                  variant="outlined"
                  value={editingTherapist.userId}
                  onChange={(
                    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setUserIdError(false);
                    handleUserIdChange(e.target.value);
                  }}
                  error={userIdError}
                  disabled={!creatingNew}
                  required
                />
                <DropdownSelect
                  label="Classification"
                  value={{
                    label:
                      editingTherapist.classId === ""
                        ? ""
                        : classes[editingTherapist.classId].name,
                    value: editingTherapist.classId,
                  }}
                  handleChange={(option) => {
                    if (option) {
                      setClassError(false);
                      handleClassChange(
                        (option as DropdownSelectorOption).value ?? ""
                      );
                    }
                  }}
                  options={Object.entries(classes).map(([id, clss]) => ({
                    label: clss.name,
                    value: id,
                  }))}
                  error={classError}
                  required
                />
                <DropdownSelect
                  label="Grade"
                  value={{
                    label:
                      editingTherapist.classId === "" ||
                      editingTherapist.gradeId === ""
                        ? ""
                        : classes[editingTherapist.classId].grades[
                            editingTherapist.gradeId
                          ].name,
                    value: editingTherapist.gradeId,
                  }}
                  handleChange={(option) => {
                    if (option) {
                      setGradeError(false);
                      handleGradeChange(
                        (option as DropdownSelectorOption).value ?? ""
                      );
                    }
                  }}
                  options={Object.entries(
                    editingTherapist.classId === ""
                      ? ""
                      : classes[editingTherapist.classId].grades
                  ).map(([id, grade]) => ({
                    label: grade.name,
                    value: id,
                  }))}
                  error={gradeError}
                  clearOnSelect
                  required
                />
                <SchoolSelectionTable
                  selected={editingTherapist.schoolIds}
                  addSchool={handleAddSchool}
                  removeSchool={handleRemoveSchool}
                />
                <ConfirmDenyButtonAction
                  confirmText={creatingNew ? "Create" : "Save"}
                  confirmAction={saveOrCreate}
                  denyText={"Cancel"}
                  denyAction={() =>
                    isEditing ? setCancelConfirmOpen(true) : handleExit()
                  }
                />
              </Column>

              <ConfirmationModal
                open={cancelConfirmOpen}
                setOpen={setCancelConfirmOpen}
                onConfirm={() => {
                  setCancelConfirmOpen(false);
                  handleExit();
                }}
                onCancel={() => setCancelConfirmOpen(false)}
                message={
                  "You have unsaved changes that will be lost. Are you sure you want to quit?"
                }
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
