import React from "react";
import { Switch } from "react-router-dom";
import ProtectedRoute from "../auth/protect-route";
import UserView from "../views/UserView";
import ProgressView from "../views/ProgressView";

export default function UserPageSwitcher(): JSX.Element {
  return (
    <Switch>
      <ProtectedRoute exact path="/" component={UserView} />
      <ProtectedRoute exact path="/progress" component={ProgressView} />
    </Switch>
  );
}
