import React from "react";
import { Redirect, Switch } from "react-router-dom";
import ProtectedRoute from "../auth/protect-route";
import BillingView from "../views/BillingView";
import DistrictView from "../views/DistrictView";
import IndividualStaffView from "../views/IndividualStaffView";
import InvoiceView from "../views/InvoiceView";
import ServicesView from "../views/ServicesView";
import StaffOverview from "../views/StaffOverview";
import IndividualServicePage from "./IndividualServicePage";
import InterventionGroupPage from "./InterventionGroupPage";
import ExportsView from "../views/ExportsView";
import ProgressView from "../views/ProgressView";

export default function PageSwitcher(): JSX.Element {
  return (
    <Switch>
      <Redirect exact from={"/"} to={"/billing"} />
      <ProtectedRoute exact path="/billing" component={BillingView} />
      <ProtectedRoute exact path="/districts" component={DistrictView} />
      <ProtectedRoute exact path="/staff" component={StaffOverview} />
      <ProtectedRoute exact path="/services" component={ServicesView} />
      <ProtectedRoute exact path="/invoices" component={InvoiceView} />
      <ProtectedRoute path="/staff/:id" component={IndividualStaffView} />
      <ProtectedRoute path="/services/:id" component={IndividualServicePage} />
      <ProtectedRoute
        path="/groups/:groupId"
        component={InterventionGroupPage}
      />
      <ProtectedRoute path="/exports" component={ExportsView} />
      <ProtectedRoute path="/progress" component={ProgressView} />
    </Switch>
  );
}
