import { JSONSchemaType } from "ajv";
import { ajv } from ".";

export interface ListStudents {
  dateWorked?: {
    $gte?: string;
    $lte?: string;
  };
  schoolId?: string;
}

export const listSchema: JSONSchemaType<ListStudents> = {
  type: "object",
  properties: {
    dateWorked: {
      type: "object",
      properties: {
        $gte: { type: "string", format: "iso-date-time", nullable: true },
        $lte: { type: "string", format: "iso-date-time", nullable: true },
      },
      additionalProperties: false,
      nullable: true,
    },
    schoolId: { type: "string", nullable: true },
  },
  additionalProperties: false,
};

export const studentValidators = {
  validateList: ajv.compile(listSchema),
};
