import { Paper } from "@material-ui/core";
import * as durationMath from "duration-fns";
import { DateTime, Interval } from "luxon";
import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { ListServiceRecords } from "../api/schemas/service-records/schema";
import { useApi } from "../api/useApi";
import { ISchool, ITimesheetRecord } from "../common/interfaces";
import { user_view_loading_missing_therapist } from "../common/Messages";
import { toInterval, unitsToHours } from "../common/Utils";
import Column from "../components/Display/Column";
import HeaderWithRightSection from "../components/Display/HeaderWithRightSection";
import LoadingSplash from "../components/Display/LoadingSplash";
import { SideBySide } from "../components/Display/SideBySide";
import { UserActions } from "../components/Input/UserActions";
import WorkItemForm from "../components/Input/WorkItemForm";
import { RangeDatePicker } from "../components/RangeDatePicker/RangeDatePicker";
import {
  ServiceRecordTable,
  StaffViewSsrTableHeader,
} from "../components/ServiceRecordTable";
import TimeSheetTable from "../components/TimeSheetTable";
import UserTimesheetSubmissionFormModal from "../components/UserTimesheetFormModal";
import { TimesheetContext } from "../context/TimesheetContext";
import { UserContext } from "../context/UserContext";
import { useDateRanges } from "../hooks/useDateRanges";
import useModal from "../hooks/useModal";

export default function UserView(): JSX.Element {
  const { timesheetRecords, isLoading: timesheetsLoading } =
    React.useContext(TimesheetContext);
  const { therapist } = React.useContext(UserContext);
  const { dateRanges } = useDateRanges();
  const api = useApi();
  const queryClient = useQueryClient();

  const [selectedSchool, setSelectedSchool] = React.useState<
    ISchool | undefined
  >();

  const [showInvoiced, setShowInvoiced] = React.useState(false);

  const [selectedStartDate, setSelectedStartDate] = React.useState<DateTime>(
    dateRanges.thisWeek.start
  );

  const [selectedEndDate, setSelectedEndDate] = React.useState<DateTime>(
    dateRanges.thisWeek.end
  );

  const serviceRecordQueryParams: ListServiceRecords = {
    dateWorked: {
      $gte: selectedStartDate.toISO(),
      $lte: selectedEndDate.toISO(),
    },
    classId: therapist?.classId,
    therapistUserId: therapist?.userId,
    invoiceId: showInvoiced ? undefined : "null",
    schoolId: selectedSchool?.id,
  };

  const { data, isLoading: areFilteredWorkItemsLoading } = useQuery({
    queryKey: api.serviceRecord.list.queryKey(serviceRecordQueryParams),
    queryFn: () => api.serviceRecord.list(serviceRecordQueryParams),
  });
  const filteredWorkItems = data ?? [];
  const { modal: WorkItemFormModal, toggle: toggleWorkItemFormModal } =
    useModal();

  const [showTimesheetForm, setShowTimesheetForm] =
    React.useState<boolean>(false);

  const isWithinCurrentTimeRange = (sheet: ITimesheetRecord) => {
    if (selectedStartDate.valueOf() >= selectedEndDate.valueOf()) return false;
    const currentInterval = toInterval(selectedStartDate, selectedEndDate);
    return (
      currentInterval.contains(DateTime.fromISO(sheet.clockIn)) &&
      currentInterval.contains(DateTime.fromISO(sheet.clockOut))
    );
  };

  const convertSheetToInterval = (sheet: ITimesheetRecord) => {
    return toInterval(
      DateTime.fromISO(sheet.clockIn),
      DateTime.fromISO(sheet.clockOut)
    );
  };

  const applicableTimesheets = timesheetRecords.filter(
    isWithinCurrentTimeRange
  );

  const totalTimeSheet =
    durationMath.toHours(
      applicableTimesheets
        .map(convertSheetToInterval)
        .map(
          (interval: Interval) =>
            interval.toDuration("milliseconds").toObject().milliseconds ?? 0
        )
        .reduce((prev, curr) => durationMath.sum(prev, curr), {})
    ) -
    applicableTimesheets
      .map((sheet) => parseInt(sheet.break_units))
      .reduce((prev, curr) => prev + curr, 0) *
      0.25;

  const totalUnits = unitsToHours(
    filteredWorkItems
      .filter((item) => !item.invoiceId)
      .reduce((prev, curr) => prev + curr.units, 0)
  );

  if (!therapist)
    return (
      <LoadingSplash
        message={"Loading User View"}
        timeoutMessage={user_view_loading_missing_therapist}
      />
    );

  return (
    <Paper style={{ padding: "10px", height: "auto" }}>
      <Column gap="m">
        <HeaderWithRightSection
          leftSection={
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <UserActions
                newTimeSheetAction={() => {
                  setShowTimesheetForm(true);
                }}
                newWorkItemAction={() => {
                  toggleWorkItemFormModal();
                }}
              />
              <RangeDatePicker
                style={{ height: "54px", width: "530px" }}
                start={selectedStartDate}
                end={selectedEndDate}
                predefinedOptions={Object.values(dateRanges)}
                setStart={setSelectedStartDate}
                setEnd={setSelectedEndDate}
              />
            </div>
          }
          rightSection={
            <SideBySide
              totalTimeSheet={totalTimeSheet}
              totalUnits={totalUnits}
            />
          }
        />

        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <TimeSheetTable
            timesheetRecords={timesheetRecords}
            isLoading={timesheetsLoading}
            selectedTherapist={therapist}
            start={selectedStartDate}
            end={selectedEndDate}
            setStartDate={setSelectedStartDate}
            setEndDate={(end: DateTime | null) => {
              if (end) setSelectedEndDate(end);
            }}
            hideAddButton={true}
            hideExportButton={true}
            style={{ marginTop: "4px", height: "70vh" }}
          />
          <ServiceRecordTable
            ssrs={filteredWorkItems}
            header={
              <StaffViewSsrTableHeader
                ssrs={filteredWorkItems}
                invalidateQuery={() =>
                  queryClient.invalidateQueries(
                    api.serviceRecord.list.queryKey(serviceRecordQueryParams)
                  )
                }
                therapist={therapist}
                showInvoicedSsrs={showInvoiced}
                setShowInvoicedSsrs={setShowInvoiced}
                school={selectedSchool}
                setSchool={setSelectedSchool}
                hideCreateSsrButton={true}
              />
            }
            isLoading={areFilteredWorkItemsLoading}
            excludeColumns={new Set(["Therapist", "Date Logged"])}
            invalidateQuery={() =>
              queryClient.invalidateQueries(
                api.serviceRecord.list.queryKey(serviceRecordQueryParams)
              )
            }
            startDate={selectedStartDate}
            setStartDate={setSelectedStartDate}
            endDate={selectedEndDate}
            setEndDate={setSelectedEndDate}
            showInvoicedSsrs={showInvoiced}
            setShowInvoicedSsrs={setShowInvoiced}
            style={{ marginTop: "4px", height: "70vh" }}
            enableExport={false}
          />
        </div>
      </Column>

      {therapist && (
        <WorkItemFormModal>
          <WorkItemForm
            therapist={therapist}
            setOpen={toggleWorkItemFormModal}
          />
        </WorkItemFormModal>
      )}

      <UserTimesheetSubmissionFormModal
        open={showTimesheetForm}
        setOpen={setShowTimesheetForm}
      />
    </Paper>
  );
}
