import { CircularProgress, LinearProgress, Paper } from "@material-ui/core";
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@material-ui/data-grid";
import { DateTime } from "luxon";
import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import { Intervention } from "../api/schemas/interventions/schema";
import { useApi } from "../api/useApi";
import { DropdownSelectorOption, ISchool } from "../common/interfaces";
import { removeDuplicates, useQueriesById } from "../common/Utils";
import SectionHeaderText from "../components/Display/SectionHeaderText";
import DropdownSelect from "../components/Input/DropdownSelect";
import { RangeDatePicker } from "../components/RangeDatePicker/RangeDatePicker";
import { SchoolContext } from "../context/SchoolContext";
import { UserContext } from "../context/UserContext";
import { useDataGridStyles } from "../hooks/useDatagridStyles";
import { useDateRanges } from "../hooks/useDateRanges";

const PRE_LOAD_EXPORT_CONTENT =
  "ERROR: NOT LOADED YET. PLEASE WAIT FOR ALL DATA TO LOAD BEFORE EXPORTING.";

const escapeFormattingBugCharacters = (str: string) => {
  const hasComma = str.includes(",");
  const hasNewLine = str.includes("\n");

  // Fix both!
  if (hasComma && hasNewLine) {
    const escapedStr = str.replace(/\n/g, " ");
    return `"${escapedStr}"`;
  }

  // Fix just commas!
  if (hasComma) {
    return `"${str}"`;
  }

  // Fix just new lines!
  if (hasNewLine) {
    const escapedStr = str.replace(/\n/g, " ");
    return escapedStr;
  }

  // Fix nothing! WOW! I LOVE CODE :DDDDDDDDDdd.dfsdfsdfksahdf;lksadfhsadjkf;asd
  return str;
};

function PivotTable({
  selectedStartDate,
  selectedEndDate,
  selectedSchool,
  selectedStudent,
}: {
  selectedStartDate?: DateTime;
  selectedEndDate?: DateTime;
  selectedSchool?: ISchool;
  selectedStudent?: string;
}) {
  const api = useApi();
  const { isAdmin } = useContext(UserContext);

  const queryParams = {
    dateWorked: {
      $gte: selectedStartDate?.toISO(),
      $lte: selectedEndDate?.toISO(),
    },
    schoolId: selectedSchool?.id,
    student: selectedStudent,
  };

  const { data: serviceRecords, isLoading: ssrQueryIsLoading } = useQuery({
    queryKey: api.serviceRecord.list.queryKey(queryParams),
    queryFn: () => api.serviceRecord.list(queryParams),
    enabled: !!(
      selectedStartDate &&
      selectedEndDate &&
      selectedSchool &&
      selectedStudent
    ),
  });

  const interventionsById = useQueriesById({
    ids: removeDuplicates(
      (serviceRecords ?? []).reduce(
        (interventions, ssr) => interventions.concat(ssr.interventionIds),
        [] as string[]
      )
    ),
    api: api.intervention.get,
    idKey: "_id",
  });

  const therapistsById = useQueriesById({
    ids: removeDuplicates(
      (serviceRecords ?? []).map((ssr) => ssr.therapistUserId)
    ),
    api: api.therapist.get,
    idKey: "userId",
    enabled: isAdmin,
  });

  const tableRows = (serviceRecords ?? []).map((ssr) => ({
    id: ssr._id,
    serviceDate: ssr.hasStartTime
      ? DateTime.fromISO(ssr.dateWorked)
      : DateTime.fromISO(ssr.dateWorked).endOf("day"),
    startTime: ssr.hasStartTime
      ? DateTime.fromISO(ssr.dateWorked).toLocaleString(DateTime.TIME_SIMPLE)
      : null,
    therapistName: therapistsById[ssr.therapistUserId]?.name ?? null,
    workType: ssr.workTypeName,
    units: ssr.units,
    // Set to null if any of the interventions are still loading.
    interventions: ssr.interventionIds.reduce(
      (accum, id) => {
        if (accum === null) return null;
        if (interventionsById[id] === undefined) return null;
        return [...accum, interventionsById[id]];
      },
      [] as Intervention[] | null
    ),
    narrativeNote: ssr.narrativeNote,
  }));

  const tableColumns: GridColDef[] = [
    {
      headerName: "Service Date",
      field: "serviceDate",
      minWidth: 140,
      valueFormatter: ({ row }) =>
        (row.serviceDate as DateTime).toLocaleString(DateTime.DATE_SHORT),
      renderCell: ({ row }) =>
        (row.serviceDate as DateTime).toLocaleString(DateTime.DATE_SHORT),
      filterable: false,
    },
    {
      headerName: "Start Time",
      field: "startTime",
      sortable: false,
    },
    {
      headerName: "Units",
      field: "units",
      width: 90,
    },
    ...(isAdmin
      ? [
          {
            headerName: "Therapist",
            field: "therapistName",
            flex: 1,
            valueFormatter: ({ row }) =>
              escapeFormattingBugCharacters(
                (row.therapistName as string | null) ?? PRE_LOAD_EXPORT_CONTENT
              ),
            renderCell: ({ row }) => {
              const therapistName = row.therapistName as string | null;
              if (therapistName === null) {
                return <LinearProgress style={{ width: "100%" }} />;
              } else {
                return therapistName;
              }
            },
          } as GridColDef,
        ]
      : []),
    {
      headerName: "Work Type",
      field: "workType",
      flex: 1,
      valueFormatter: ({ row }) => escapeFormattingBugCharacters(row.workType),
      renderCell: ({ row }) => row.workType,
    },
    {
      headerName: "Interventions",
      field: "interventions",
      flex: 2,
      valueFormatter: ({ row }) =>
        escapeFormattingBugCharacters(
          (row.interventions as Intervention[] | null)
            ?.map((intervention) => intervention.text)
            .join("\n") ?? PRE_LOAD_EXPORT_CONTENT
        ),
      valueGetter: ({ row }) =>
        ((row.interventions as Intervention[] | null) || [])
          .map((intervention) => intervention.text)
          .join(""),
      renderCell: ({ row }) => {
        const interventions = row.interventions as Intervention[] | null;
        if (interventions === null) {
          return <LinearProgress style={{ width: "100%" }} />;
        } else {
          return (
            <ul style={{ marginBottom: "0px" }}>
              {interventions.map((intervention) => (
                <li key={intervention._id}>{intervention.text}</li>
              ))}
            </ul>
          );
        }
      },
    },
    {
      headerName: "Narrative Note",
      field: "narrativeNote",
      flex: 3,
      valueFormatter: ({ row }) =>
        escapeFormattingBugCharacters(row.narrativeNote),
      renderCell: ({ row }) => row.narrativeNote,
    },
  ];

  return (
    <DataGrid
      className={useDataGridStyles().className}
      style={{ marginTop: "8px", height: "77vh" }}
      columns={tableColumns}
      rows={tableRows}
      loading={ssrQueryIsLoading}
      components={{
        LoadingOverlay: () => (
          <GridOverlay>
            <CircularProgress size={70} />
          </GridOverlay>
        ),
        Toolbar: () => (
          <GridToolbarContainer>
            {selectedStartDate &&
              selectedEndDate &&
              selectedSchool &&
              selectedStudent && (
                <div style={{ display: "flex", gap: "10px" }}>
                  <GridToolbarFilterButton />
                  <GridToolbarColumnsButton />
                  <GridToolbarExport
                    csvOptions={{
                      fileName:
                        `${selectedSchool.invoicePrefix}` +
                        `-${selectedStudent.replaceAll(" ", "_").toLocaleUpperCase()}` +
                        `-${selectedStartDate.toLocaleString(DateTime.DATE_SHORT)}` +
                        `-${selectedEndDate.toLocaleString(DateTime.DATE_SHORT)}`,
                    }}
                  />
                </div>
              )}
          </GridToolbarContainer>
        ),
      }}
      autoHeight={false}
      disableSelectionOnClick
      disableColumnMenu
    />
  );
}

export default function ProgressView() {
  const api = useApi();
  const { dateRanges } = useDateRanges();
  const schoolContext = React.useContext(SchoolContext);

  const [selectedStartDate, setSelectedStartDate] = useState<
    DateTime | undefined
  >();
  const [selectedEndDate, setSelectedEndDate] = useState<
    DateTime | undefined
  >();
  const [selectedSchool, setSelectedSchool] = useState<ISchool | undefined>(
    undefined
  );
  const [selectedStudent, setSelectedStudent] = useState<string | undefined>(
    undefined
  );
  const queryParams = {
    dateWorked: {
      $gte: selectedStartDate?.toISO(),
      $lte: selectedEndDate?.toISO(),
    },
    schoolId: selectedSchool?.id,
  };

  const { data: students, isLoading: studentsQueryIsLoading } = useQuery({
    queryKey: api.student.list.queryKey(queryParams),
    queryFn: () => api.student.list(queryParams),
    enabled: !!(selectedStartDate && selectedEndDate && selectedSchool),
  });

  return (
    <Paper style={{ padding: "10px", height: "auto" }}>
      <SectionHeaderText>Progress Tracker</SectionHeaderText>
      <div
        style={{
          width: "100%",
          gap: "8px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <RangeDatePicker
          style={{ height: "54px", width: "100%" }}
          start={selectedStartDate}
          end={selectedEndDate}
          predefinedOptions={Object.values(dateRanges)}
          setStart={setSelectedStartDate}
          setEnd={setSelectedEndDate}
        />
        <DropdownSelect
          style={{ width: "100%" }}
          label={"School"}
          isDisabled={!selectedStartDate || !selectedEndDate}
          selectedOption={
            selectedSchool
              ? {
                  label: `${selectedSchool.name} ${selectedSchool.invoicePrefix}`,
                  value: selectedSchool.id,
                }
              : null
          }
          handleChange={(change) => {
            const schoolId = (change as DropdownSelectorOption | null)?.value;
            setSelectedSchool(
              schoolId ? schoolContext.schoolsById[schoolId] : undefined
            );
            setSelectedStudent(undefined);
          }}
          options={schoolContext.schools.map((school) => ({
            label: `${school.name} ${school.invoicePrefix}`,
            value: school.id,
          }))}
          forcePlaceholder
          isClearable
        />
        <DropdownSelect
          style={{ width: "100%" }}
          label={"Student"}
          isLoading={studentsQueryIsLoading}
          isDisabled={!selectedSchool}
          selectedOption={
            selectedStudent
              ? {
                  label: selectedStudent,
                  value: selectedStudent,
                }
              : null
          }
          handleChange={(option) => {
            const studentName = (option as DropdownSelectorOption | null)
              ?.value;
            setSelectedStudent(studentName ? studentName : undefined);
          }}
          options={
            students?.map((student) => ({
              label: student.name,
              value: student.name,
            })) || []
          }
          forcePlaceholder
          isClearable
        />
      </div>
      <PivotTable
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        selectedSchool={selectedSchool}
        selectedStudent={selectedStudent}
      />
    </Paper>
  );
}
