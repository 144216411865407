import { Paper, Typography } from "@material-ui/core";
import React from "react";
import {
  DropdownSelectorOption,
  IInvoiceObject,
  ISchool,
} from "../common/interfaces";
import DropdownSelect from "../components/Input/DropdownSelect";
import { SchoolContext } from "../context/SchoolContext";
import FileSaver from "file-saver";
import { useApi } from "../api/useApi";
import Toaster, { ToastType } from "../common/Toaster";
import { createLogger } from "../components/Logging/Logging";
import { getContentType, isContentCSV, isContentJSON } from "../common/Utils";
import { DateTime } from "luxon";
import ConfirmationModal from "../components/ConfirmationModal";
import { WarningRoundedIcon } from "../icons";
import InvoiceTable from "../components/InvoiceTable";
import SectionHeaderText from "../components/Display/SectionHeaderText";
import { useQuery, useQueryClient } from "react-query";

const logger = createLogger("InvoiceView");

export default function InvoiceView(): JSX.Element {
  const api = useApi();
  const queryClient = useQueryClient();
  const schoolContext = React.useContext(SchoolContext);

  const { data: invoices, isLoading: invoicesLoading } = useQuery({
    queryFn: api.invoice.list,
    queryKey: api.invoice.list.queryKey(),
  });

  const [selectedSchool, setSelectedSchool] = React.useState<
    ISchool | undefined
  >();

  const filteredInvoices = (invoices || []).filter((inv) =>
    selectedSchool === undefined ? true : inv.schoolId === selectedSchool.id
  );

  const [showConfirmInvoiceDelete, setShowConfirmInvoiceDelete] =
    React.useState<boolean>(false);
  const [invoiceToDelete, setInvoiceToDelete] = React.useState<
    IInvoiceObject | undefined
  >();

  const downloadInvoice = React.useCallback(
    (inv: IInvoiceObject) => {
      api.invoice.file.pdf
        .get({
          id: inv._id,
        })
        .then(async (res) => {
          await res
            .json()
            .then((jobj) => {
              if (res.ok) {
                const incoming_invoice = JSON.parse(jobj);
                if (
                  incoming_invoice === undefined ||
                  incoming_invoice.blob === undefined
                ) {
                  throw new Error(
                    "Error: Invoice returned from server undefined"
                  );
                }
                const invoiceNumber = inv.invoiceNumber;
                FileSaver.saveAs(
                  "data:application/pdf;base64," + incoming_invoice.blob,
                  `invoice_${invoiceNumber}.pdf`
                );
              } else {
                logger.error(
                  `ERROR Code ${res.status}: ${res.statusText} => Render Error`,
                  res
                );
              }
            })
            .catch((err) => {
              Toaster(err.message, ToastType.error);
              logger.error(err);
            });
        })
        .catch((err) => {
          logger.error(err);
        });
    },
    [api.invoice.file.pdf]
  );

  const exportInvoice = React.useCallback(
    (inv: IInvoiceObject) => {
      api.invoice.file.csv.get({ invoiceIds: [inv._id] }).then(async (res) => {
        if (res.ok) {
          if (isContentCSV(res)) {
            const text = await res.text();
            const fname = `${
              schoolContext.schools
                .find((sch) => sch.id === inv.schoolId)
                ?.name.replaceAll(" ", "_") ?? "Unknown"
            }_export_${DateTime.local()
              .toLocaleString()
              .replace("/", "_")}.csv`;

            FileSaver.saveAs(new Blob([text]), fname);
          } else {
            Toaster(
              `Server Returned Unexpected Content Type: ${getContentType(res)}`,
              ToastType.error
            );
            logger.error("Unexpected Content Type", { res, inv });
          }
        } else {
          switch (res.status) {
            case 406:
              Toaster(
                `Error: Invalid Request from Client: ${res.statusText}`,
                ToastType.error
              );
              logger.error(`${res.status}: ${res.statusText}`, { res, inv });
              break;
            default:
              Toaster(
                `Unknown Error Occured: ${res.statusText}`,
                ToastType.error
              );
              logger.error(`${res.status}: ${res.statusText}`, { res, inv });
          }
        }
      });
    },
    [api.invoice.file.csv, schoolContext.schools]
  );

  const deleteInvoice = React.useCallback((invoice: IInvoiceObject) => {
    setInvoiceToDelete(invoice);
    setShowConfirmInvoiceDelete(true);
  }, []);

  const confirmInvoiceDelete = React.useCallback(() => {
    if (!invoiceToDelete) {
      Toaster("Selected invoice could not be found.", ToastType.error);
      return;
    }
    api.invoice
      .delete({ invoiceId: invoiceToDelete.invoiceNumber })
      .then((res) => {
        if (res.status === 200) {
          Toaster(
            `${invoiceToDelete.invoiceNumber} deleted sucessnfully.`,
            ToastType.good
          );
        } else if (res.status === 404) {
          Toaster(
            "Cannot find endpoint, possible that the backend is down or out of date.",
            ToastType.error
          );
        } else {
          if (isContentJSON(res)) {
            res.json().then((j) => {
              Toaster(`Error ${j.message}`, ToastType.error);
            });
          }
        }
      })
      .catch((err: unknown) => {
        Toaster(`Unknown Error: ${err}`, ToastType.error);
        console.log(err);
      });

    setInvoiceToDelete(undefined);
    setShowConfirmInvoiceDelete(false);
    // For some reason this is very slow to reload the query, I'm guessing because of the client-side filtering
    queryClient.invalidateQueries(api.invoice.list.queryKey());
    // This disgusting, but it was here before and it solves the query reload issue, so I'm keeping it
    window.location.reload();
  }, [api.invoice, invoiceToDelete, queryClient]);

  const cancelInvoiceDelete = React.useCallback(() => {
    setShowConfirmInvoiceDelete(false);
  }, []);

  return (
    <Paper style={{ padding: "10px", height: "auto" }}>
      <SectionHeaderText>Invoices</SectionHeaderText>
      <DropdownSelect
        style={{ width: "100%", marginBottom: "4px" }}
        label={"All Schools"}
        selectedOption={
          selectedSchool
            ? {
                label: `${selectedSchool.name} ${selectedSchool.invoicePrefix}`,
                value: selectedSchool.id,
              }
            : null
        }
        handleChange={(change) => {
          const schoolId = (change as DropdownSelectorOption | null)?.value;
          setSelectedSchool(
            schoolId
              ? schoolContext.schools.find((school) => school.id === schoolId)
              : undefined
          );
        }}
        options={schoolContext.schools.map((school) => ({
          label: `${school.name} ${school.invoicePrefix}`,
          value: school.id,
        }))}
        forcePlaceholder
        isClearable
      />
      <InvoiceTable
        invoices={filteredInvoices}
        onDownload={downloadInvoice}
        onExport={exportInvoice}
        onDelete={deleteInvoice}
        isLoading={invoicesLoading}
      />
      <ConfirmationModal
        message={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <WarningRoundedIcon
                fontSize="large"
                style={{ marginRight: "10px" }}
              />
              <Typography align="center">
                This operation is <b>irreversible!</b> Are you sure you want to
                delete this invoice?
              </Typography>
            </div>
            <Typography variant="h4">
              {invoiceToDelete?.invoiceNumber}
            </Typography>
          </div>
        }
        confirmText={`Yes, delete ${invoiceToDelete?.invoiceNumber}`}
        cancelText="No, do not delete invoice"
        onConfirm={confirmInvoiceDelete}
        onCancel={cancelInvoiceDelete}
        open={showConfirmInvoiceDelete}
        setOpen={cancelInvoiceDelete}
      />
    </Paper>
  );
}
