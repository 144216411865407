import { makeStyles, createStyles } from "@material-ui/styles";
import React, { ChangeEvent } from "react";
import { TherapistContext } from "../context/TherapistContext";
import clsx from "clsx";
import { ServicesContext } from "../context/ServicesContext";
import { SchoolContext } from "../context/SchoolContext";
import Toaster, { ToastType } from "../common/Toaster";
import TimeSheetTable from "../components/TimeSheetTable";
import { TimesheetContext } from "../context/TimesheetContext";
import { IndividualStaffViewStaffEditForm } from "../components/IndividualStaffView/IndividualStaffViewStaffEditForm";
import { Button, Divider, Paper } from "@material-ui/core";
import {
  ITherapist,
  ISchool,
  DropdownSelectorOption,
} from "../common/interfaces";
import { useApi } from "../api/useApi";
import { useHistory, useParams } from "react-router-dom";
import { createLogger } from "../components/Logging/Logging";
import { SchoolSelectionTable } from "../components/IndividualStaffView/SchoolSelectionTable";
import ConfirmationModal from "../components/ConfirmationModal";
import { BoldNumberDisplay } from "../components/Display/BoldNumberDisplay";
import { RangeDatePicker } from "../components/RangeDatePicker/RangeDatePicker";
import LoadingSplash from "../components/Display/LoadingSplash";
import { DateTime } from "luxon";
import HeaderWithRightSection from "../components/Display/HeaderWithRightSection";
import Row from "../components/Display/Row";
import SideDrawer from "../components/Display/SideDrawer";
import { ConfirmDenyButtonAction } from "../components/Input/ConfirmDenyButtonAction";
import Column from "../components/Display/Column";
import SectionHeaderText from "../components/Display/SectionHeaderText";
import BackButton from "../components/Input/BackButton";
import IconText from "../components/Display/IconText";
import { AccountCircleIcon } from "../icons";
import { useDateRanges } from "../hooks/useDateRanges";
import { useQuery, useQueryClient } from "react-query";
import {
  ServiceRecordTable,
  StaffViewSsrTableHeader,
} from "../components/ServiceRecordTable";
import { ListServiceRecords } from "../api/schemas/service-records/schema";
const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      borderRadius: "10px",
    },
    inlineContainer: {
      display: "inline",
    },
    centeringContainer: {
      display: "block",
    },
    defaultMessage: {
      fontSize: 18,
      textAlign: "center",
    },
    button: {
      margin: "10px",
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
    },
    flexRow: {
      display: "flex",
      flexDirection: "row",
    },
    label: {
      textAlign: "center",
      fontSize: "12",
      width: "100%",
      margin: "auto",
    },
    error: {
      border: "1px red solid",
    },
    scrollable: {
      overflowY: "auto",
    },
    fixedMaxHieght: {
      maxHeight: "300px",
    },
    input: {
      width: "95%",
      margin: "5px auto",
      minWidth: "10%",
    },
    editingControls: {
      padding: "10px",
    },
    editForm: {
      // minHeight: "700px"
    },
    spaceBetween: {
      justifyContent: "space-between",
    },
    table: {
      padding: "5px",
    },
    heading: {
      borderRadius: "5px",
    },
    workItemTableToolBarDropdown: {
      width: "fit-content",
      minWidth: "150px",
      maxWidth: "400px",
    },
    datePickerContainer: {
      margin: "5px",
      padding: "5px 0px",
      marginLeft: "auto",
    },
    name: {
      alignSelf: "center",
      paddingLeft: "5px",
    },
    profileIcon: {
      margin: "auto",
    },
  })
);

const logger = createLogger("IndividualStaffView");

export default function IndividualStaffView(): JSX.Element {
  const therapistContext = React.useContext(TherapistContext);
  const api = useApi();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  React.useEffect(() => {
    const lookup = therapistContext.therapists.find(
      (therapist) => therapist.userId === id
    );
    if (lookup) therapistContext.setSelectedTherapist(lookup);
  }, [id, therapistContext]);

  const { classes } = React.useContext(ServicesContext);
  const { schools } = React.useContext(SchoolContext);
  const { timesheetRecords, timesheetsLoading } =
    React.useContext(TimesheetContext);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hasChanges, setHasChanges] = React.useState<boolean>(false);
  const [cancelConfirmation, setShowCancelConfirmation] =
    React.useState<boolean>(false);
  const [nameError, setNameError] = React.useState<boolean>(false);
  const [classError, setClassError] = React.useState<boolean>(false);
  const [gradeError, setGradeError] = React.useState<boolean>(false);
  const [showInvoiced, setShowInvoiced] = React.useState(false);
  const [school, setSchool] = React.useState<ISchool | undefined>();
  const [staffDetailsDrawerOpened, setStaffDetailsDrawerOpened] =
    React.useState<boolean>(false);

  const styles = useStyles();

  const [editingTherapist, setEditingTherapist] = React.useState<
    ITherapist | undefined
  >(therapistContext.selectedTherapist);

  React.useEffect(
    () => setEditingTherapist(therapistContext.selectedTherapist),
    [therapistContext.selectedTherapist]
  );

  const { dateRanges } = useDateRanges();

  const [selectedStartDate, setSelectedStartDate] = React.useState<DateTime>(
    dateRanges.thisWeek.start
  );

  const [selectedEndDate, setSelectedEndDate] = React.useState<DateTime>(
    dateRanges.thisWeek.end
  );

  const serviceRecordQueryParams: ListServiceRecords = {
    dateWorked: {
      $gte: selectedStartDate.toISO(),
      $lte: selectedEndDate.toISO(),
    },
    therapistUserId: editingTherapist?.userId,
    invoiceId: showInvoiced ? undefined : "null",
    schoolId: school?.id,
  };

  const { data, isLoading: areFilteredWorkItemsLoading } = useQuery({
    queryKey: api.serviceRecord.list.queryKey(serviceRecordQueryParams),
    queryFn: () => api.serviceRecord.list(serviceRecordQueryParams),
    placeholderData: [],
  });
  const filteredWorkItems = data ?? [];
  const totalUnitHours =
    filteredWorkItems.map((r) => r.units).reduce((a, u) => a + u, 0) / 4;
  const queryClient = useQueryClient();

  const setAttribute = React.useCallback(
    (key: keyof ITherapist, value: unknown) => {
      if (!editingTherapist) {
        logger.error(`editingTherapist DNE; Failed ${key} <= ${value}`);
        return;
      }
      if (key in editingTherapist) {
        logger.info(`Updating editingTherapist: ${key} <= ${value}`);
        setEditingTherapist({ ...editingTherapist, [key]: value });
      } else {
        logger.error(`${key} not in  ${editingTherapist}`);
      }
    },
    [editingTherapist]
  );

  const handleNameChange = React.useCallback(
    (newName: string) => {
      if (editingTherapist === undefined) return;
      setAttribute("name", newName);
      setHasChanges(true);
    },
    [editingTherapist, setAttribute]
  );

  const handleClassChange = React.useCallback(
    (newClassId: string) => {
      if (editingTherapist === undefined) return;

      setEditingTherapist({
        ...editingTherapist,
        classId: newClassId,
        gradeId: "",
      });
      setGradeError(true);
      setHasChanges(true);
    },
    [editingTherapist]
  );

  const handleGradeChange = React.useCallback(
    (newGradeId: string) => {
      if (editingTherapist === undefined) return;
      if (!(newGradeId in classes[editingTherapist.classId].grades)) return;

      setAttribute("gradeId", newGradeId);
      setHasChanges(true);
    },
    [classes, editingTherapist, setAttribute]
  );

  const handleAddSchool = React.useCallback(
    (newSchoolId: string) => {
      if (editingTherapist === undefined) return;
      const school = schools.find((s: ISchool) => s.id === newSchoolId);
      if (school === undefined) return;
      setAttribute("schoolIds", [...editingTherapist.schoolIds, school.id]);
      setHasChanges(true);
    },
    [editingTherapist, schools, setAttribute]
  );

  const handleRemoveSchool = React.useCallback(
    (oldSchoolName: string) => {
      const oldSchoolId = schools.find(
        (school) => `${school.name} ${school.invoicePrefix}` === oldSchoolName
      )?.id;
      if (oldSchoolId === undefined || oldSchoolId.length === 0) {
        logger.error(
          `handleRemoveSchool => School ${oldSchoolName} does not exist`,
          {
            oldSchoolName,
            schools,
          }
        );
        Toaster(
          `Error: ${oldSchoolName} is not a valid district, please contact your administrator`,
          ToastType.error
        );
        logger.error(`${oldSchoolName} is not a valid district`, {
          oldSchoolName,
          oldSchoolId,
          schools,
        });
        return;
      }
      if (editingTherapist === undefined) {
        logger.error("handleRemoveSchool => editingTherapist is undefined");
        return;
      }
      const found = editingTherapist.schoolIds.find(
        (sid: string) => sid === oldSchoolId
      );
      if (!found) {
        logger.error(
          `handleRemoveSchool => could not find school in editingTherapist.schoolIds` +
            `schoolId => ${oldSchoolId}` +
            `schoolIds => ${editingTherapist.schoolIds}`,
          { oldSchoolId, schools, editingTherapist }
        );
        return;
      }
      setAttribute(
        "schoolIds",
        editingTherapist.schoolIds.filter((sid: string) => sid !== oldSchoolId)
      );
      setHasChanges(true);
    },
    [editingTherapist, schools, setAttribute]
  );

  const saveChanges = React.useCallback(() => {
    if (nameError || classError || gradeError || !editingTherapist) {
      if (nameError) Toaster("Invalid Name", ToastType.error);
      if (classError) Toaster("Invalid Class", ToastType.error);
      if (gradeError) Toaster("Invalid Grade", ToastType.error);
      if (!editingTherapist)
        Toaster("Editing Therapist Undefined; Contact Admin", ToastType.error);
      return;
    }
    if (editingTherapist.name.length === 0) {
      logger.error("editingTherapist.name.length === 0");
      setNameError(true);
      return;
    }
    if (editingTherapist.gradeId === undefined) {
      Toaster(
        "Selected Grade is not valid; Make sure selected Service has grades",
        ToastType.error
      );
      logger.error("editingTherapist.gradeName === undefined");
      setGradeError(true);
      return;
    }

    if (therapistContext.selectedTherapist === undefined) {
      logger.error("therapistContext.selectedTherapist === undefined");
      return;
    }
    api.therapist
      .update({
        id: therapistContext.selectedTherapist._id,
        updateObj: editingTherapist,
      })
      .then((res) => {
        res.json().then((data) => {
          if (res.status === 200) {
            therapistContext.triggerRefresh();
            Toaster(`${editingTherapist.name} updated.`, ToastType.good);
          } else {
            Toaster(
              `Error ${res.statusText}: ${data.message}`,
              ToastType.error
            );
          }
        });
      });
    setHasChanges(false);
  }, [
    api.therapist,
    classError,
    editingTherapist,
    gradeError,
    nameError,
    therapistContext,
  ]);

  const cancelChanges = React.useCallback(() => {
    setShowCancelConfirmation(false);
    setStaffDetailsDrawerOpened(false);
    setEditingTherapist(therapistContext.selectedTherapist);
    setHasChanges(false);
  }, [therapistContext.selectedTherapist]);

  const handleCancel = React.useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirmation(true);
    } else {
      cancelChanges();
    }
  }, [cancelChanges, hasChanges]);

  const onNameChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNameError(false);
    handleNameChange(e.target.value);
  };

  const onClassificationChange = (option: DropdownSelectorOption) => {
    if (!option.value) return;
    setClassError(false);
    handleClassChange(option.value);
  };

  const onGradeChange = (option: DropdownSelectorOption) => {
    if (!option.value) return;
    setGradeError(false);
    handleGradeChange(option.value);
  };

  const classOptions = React.useMemo(() => {
    return Object.entries(classes).map(([id, clss]) => ({
      label: clss.name,
      value: id,
    }));
  }, [classes]);

  const gradeOptions = React.useMemo(() => {
    if (
      editingTherapist === undefined ||
      !(editingTherapist.classId in classes)
    )
      return [];

    return Object.entries(classes[editingTherapist.classId].grades).map(
      ([id, grade]) => ({
        label: grade.name,
        value: id,
      })
    );
  }, [classes, editingTherapist]);

  if (!editingTherapist || !therapistContext.selectedTherapist)
    return <LoadingSplash />;
  return (
    <Paper className={clsx(styles.paper, styles.centeringContainer)}>
      <HeaderWithRightSection
        style={{ padding: "10px" }}
        leftSection={
          <Row gap="m">
            <BackButton
              onClick={() => {
                therapistContext.setSelectedTherapist(undefined);
                history.push("/staff");
              }}
            />
            <IconText
              className={styles.name}
              variant="h4"
              icon={
                <AccountCircleIcon
                  fontSize={"large"}
                  className={styles.profileIcon}
                />
              }
              text={`${
                therapistContext?.selectedTherapist?.name ?? "undefined"
              }`}
            />
            <BoldNumberDisplay
              label={"Total"}
              num={totalUnitHours}
              unit={"Hours"}
              variant={"h4"}
              compact
            />
            <RangeDatePicker
              start={selectedStartDate}
              end={selectedEndDate}
              predefinedOptions={Object.values(dateRanges)}
              setStart={setSelectedStartDate}
              setEnd={setSelectedEndDate}
            />
          </Row>
        }
        rightSection={
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setStaffDetailsDrawerOpened(true)}
            style={{ margin: "10px" }}
          >
            Edit Staff Details
          </Button>
        }
      />
      <Column gap="l" style={{ padding: "10px", paddingBottom: "10px" }}>
        <TimeSheetTable
          selectedTherapist={editingTherapist}
          timesheetRecords={timesheetRecords}
          isLoading={timesheetsLoading}
          start={selectedStartDate}
          end={selectedEndDate}
          setStartDate={setSelectedStartDate}
          setEndDate={setSelectedEndDate}
        />
        <ServiceRecordTable
          ssrs={filteredWorkItems}
          excludeColumns={new Set(["Therapist", "Date Logged"])}
          header={
            <StaffViewSsrTableHeader
              ssrs={filteredWorkItems}
              invalidateQuery={() =>
                queryClient.invalidateQueries(
                  api.serviceRecord.list.queryKey(serviceRecordQueryParams)
                )
              }
              therapist={editingTherapist}
              showInvoicedSsrs={showInvoiced}
              setShowInvoicedSsrs={setShowInvoiced}
              school={school}
              setSchool={setSchool}
            />
          }
          isLoading={areFilteredWorkItemsLoading}
          startDate={selectedStartDate}
          setStartDate={setSelectedStartDate}
          endDate={selectedEndDate}
          setEndDate={setSelectedEndDate}
          showInvoicedSsrs={showInvoiced}
          setShowInvoicedSsrs={setShowInvoiced}
          invalidateQuery={() =>
            queryClient.invalidateQueries(
              api.serviceRecord.list.queryKey(serviceRecordQueryParams)
            )
          }
          style={{ height: "50vh" }}
          enableExport={false}
        />
      </Column>
      <SideDrawer
        open={staffDetailsDrawerOpened}
        onClose={handleCancel}
        altTitleElement={
          <HeaderWithRightSection
            style={{ marginTop: "5px" }}
            leftSection={<SectionHeaderText>Staff Details</SectionHeaderText>}
            rightSection={
              <ConfirmDenyButtonAction
                style={{ width: "200px", height: "100%", margin: "0 auto" }}
                confirmAction={saveChanges}
                confirmText={"Save"}
                denyAction={handleCancel}
                denyText={"Close"}
              />
            }
          />
        }
      >
        <Column gap="l" style={{ margin: "20px" }}>
          <IndividualStaffViewStaffEditForm
            editingTherapist={editingTherapist}
            nameError={nameError}
            gradeError={gradeError}
            classError={classError}
            classOptions={classOptions}
            gradeOptions={gradeOptions}
            onNameChange={onNameChange}
            onClassificationChange={onClassificationChange}
            onGradeChange={onGradeChange}
          />
          <Divider />
          <SchoolSelectionTable
            selected={editingTherapist.schoolIds}
            addSchool={handleAddSchool}
            removeSchool={handleRemoveSchool}
            maxHeight={"450px"}
          />
        </Column>
      </SideDrawer>

      <ConfirmationModal
        message="Changes have been made. Are you sure you want to cancel?"
        confirmText="No, keep editing"
        cancelText="Yes, cancel"
        onConfirm={function (): void {
          setShowCancelConfirmation(false);
        }}
        onCancel={function (): void {
          cancelChanges();
        }}
        open={cancelConfirmation}
        setOpen={function (open: boolean): void {
          setShowCancelConfirmation(open);
        }}
      />
    </Paper>
  );
}
