import React from "react";
import { UserContext } from "./context/UserContext";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import TherapistContextProvider from "./context/TherapistContext";
import UserDashboard from "./components/UserDashboard/UserDashboard";
import InterventionGroupsContextProvider from "./context/InterventionGroupsContext";
import InterventionsContextProvider from "./context/InterventionsContext";

export default function App(): JSX.Element {
  const { isAdmin } = React.useContext(UserContext);

  if (isAdmin) {
    return (
      <TherapistContextProvider>
        <InterventionsContextProvider>
          <InterventionGroupsContextProvider>
            <AdminDashboard />
          </InterventionGroupsContextProvider>
        </InterventionsContextProvider>
      </TherapistContextProvider>
    );
  } else {
    return <UserDashboard />;
  }
}
