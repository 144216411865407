import { Grid, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import TimerOutlinedIcon from "@material-ui/icons/TimerOutlined";

export interface UserActionsProps {
  newWorkItemAction: () => void;
  newTimeSheetAction: () => void;
}

const useStyles = makeStyles({
  fabMargin: {
    margin: "5px",
  },
});

export function UserActions(props: UserActionsProps): JSX.Element {
  const { newWorkItemAction, newTimeSheetAction } = props;
  const styles = useStyles();

  return (
    <Grid item xs={6}>
      <Button
        variant="contained"
        size="medium"
        color="primary"
        aria-label="add-new-work-time"
        onClick={newTimeSheetAction}
        className={styles.fabMargin}
      >
        <TimerOutlinedIcon />
        <Typography>New Timesheet</Typography>
      </Button>
      <Button
        variant="contained"
        size="medium"
        color="primary"
        aria-label="add-new-work-time"
        onClick={newWorkItemAction}
        className={styles.fabMargin}
      >
        <AssignmentTurnedInOutlinedIcon />
        <Typography>New Student Service Record</Typography>
      </Button>
    </Grid>
  );
}
