import React, { useCallback, useMemo } from "react";
import {
  UpdateType,
  ISchool,
  ITherapist,
  ITimesheetRecord,
  IWorkItem,
  IClassWithoutWorkTypesOrGrades,
  IInvoiceObject,
} from "../common/interfaces";
import { hasProp, isContentJSON } from "../common/Utils";
import { createLogger } from "../components/Logging/Logging";
import { TokenContext } from "../context/TokenContext";
import {
  CreateIntervention,
  DeleteIntervention,
  Intervention,
  interventionValidators,
} from "./schemas/interventions/schema";
import {
  CreateInterventionsGroup,
  DeleteInterventionsGroup,
  InterventionGroup,
  interventionGroupsValidators,
  UpdateInterventionsGroup,
} from "./schemas/interventions/groups/schema";
import { PopulatedInterventionGroup } from "./schemas/interventions/groups/therapist/schema";
import {
  CreateServiceRecord,
  ListServiceRecords,
  ServiceRecord,
  serviceRecordValidators,
  Student,
  UpdateServiceRecord,
} from "./schemas/service-records/schema";
import Ajv, { ValidateFunction } from "ajv";
import QueryString from "qs";
import addFormats from "ajv-formats";

import { RetrieveStudentServiceDetails } from "./schemas/school/student/details/schemas";
import { ListStudents, studentValidators } from "./schemas/students";
export const ajv = new Ajv({
  allErrors: true,
  verbose: true,
  coerceTypes: "array",
});
addFormats(ajv);

export class UnimplementedError extends Error {
  constructor(message?: string) {
    super(message ?? "Unimplemented");
  }
}

interface MultiIdPayload {
  idsArray: string[];
}

export type PostPayload =
  | UpdateType
  | ISchool
  | ITherapist
  | string
  | IInvoiceObject
  | ITimesheetRecord
  | IWorkItem[]
  | string[]
  | MultiIdPayload
  | IClassWithoutWorkTypesOrGrades
  | { prefix: string }
  | { [key: string]: unknown[] };

export type GetPayload = { [key: string]: string | number | boolean };
export type DeletePayload = GetPayload;

export type GetCall = (
  payload: GetPayload,
  ...urlParams: string[]
) => Promise<Response>;
export type PostCall = (
  payload: PostPayload,
  ...urlParams: string[]
) => Promise<Response>;
export type DeleteCall = (...urlParams: string[]) => Promise<Response>;

const baseURL = process.env.REACT_APP_SERVER_URL;

const logger = createLogger("APIContext");

type SendProps<BodyType> = {
  method: string;
  endpoint: string;
  body?: BodyType;
  params?: {
    [key: string]: number | string | boolean;
  };
  extra?: RequestInit;
};

export function useApi() {
  const { token } = React.useContext(TokenContext);

  const serializeQueryParams = <P extends number | string | boolean>(params: {
    [key: string]: P;
  }) => {
    const queryArray = [];
    for (const key in params) {
      if (hasProp(params, key)) {
        queryArray.push(`${key}=${encodeURIComponent(params[key])}`);
      }
    }
    const final = queryArray.join("&");
    return final;
  };

  /**
   * @deprecated use send
   */
  const get = React.useCallback(
    async (endpoint: string, extra?: { [key: string]: unknown }) => {
      logger.info(`GET ${endpoint}`, extra);
      return fetch(baseURL + endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
        mode: "cors",
        ...extra,
      });
    },
    [token]
  );

  /**
   * @deprecated use send
   */
  const _delete = React.useCallback(
    async (endpoint: string, extra?: { [key: string]: unknown }) => {
      logger.info(`GET ${endpoint}`, extra);
      return fetch(baseURL + endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "DELETE",
        mode: "cors",
        ...extra,
      });
    },
    [token]
  );

  /**
   * @deprecated use send
   */
  const post = React.useCallback(
    async (
      endpoint: string,
      body?: unknown,
      extra?: { [key: string]: unknown }
    ) => {
      logger.info(`POST ${endpoint}`, { body, extra });
      return fetch(baseURL + endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        mode: "cors",
        body: JSON.stringify(body),
        ...extra,
      });
    },
    [token]
  );

  const send = useCallback(
    async <BodyType,>({
      method,
      endpoint,
      body,
      params,
      extra,
    }: SendProps<BodyType>) => {
      if (params) {
        endpoint = `${endpoint}?${serializeQueryParams(params)}}`;
      }

      return fetch(baseURL + endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method,
        mode: "cors",
        body: JSON.stringify(body),
        ...extra,
      });
    },
    [token]
  );

  const api = useMemo(
    () => ({
      school: {
        list: async (): Promise<Response> => {
          return await get("/retrieve-school");
        },
        update: async (payload: PostPayload): Promise<Response> => {
          const editById = payload as UpdateType;
          delete editById.updateObj.id;
          return await post("/edit-school", payload);
        },
        delete: async (payload: PostPayload): Promise<Response> => {
          return await post("/delete-school", {
            idsArray: payload as string[],
          });
        },

        add: async (payload: PostPayload): Promise<Response> => {
          return await post("/store-school", payload);
        },
      },
      workItem: {
        list: async (): Promise<Response> => {
          return await get(`/retrieve-work-item-interval`);
        },
        add: async (payload: PostPayload): Promise<Response> => {
          return await post("/store-work-item", payload);
        },
        delete: async (payload: PostPayload): Promise<Response> => {
          return await post("/delete-work-item", {
            idsArray: payload as string[],
          });
        },
        update: async (payload: PostPayload): Promise<Response> => {
          return await post("/edit-work-item", payload);
        },
      },
      therapist: {
        list: async (): Promise<Response> => {
          return await get("/retrieve-therapist");
        },
        /**
         * @deprecated use get
         */
        get_DEPRECATED: async (payload: GetPayload): Promise<Response> => {
          return await get(
            `/retrieve-user-info?${serializeQueryParams(payload)}`
          );
        },
        get: Object.assign(
          async (params: { id: string }) => {
            const { data } = await unwrapResponse<{ data: ITherapist }>(
              send({
                method: "GET",
                endpoint: `/therapists/${params.id}`,
              })
            );
            return data;
          },
          {
            queryKey: (params: { id: string }) => ["therapist", "get", params],
          }
        ),
        add: async (payload: PostPayload): Promise<Response> => {
          return await post("/store-therapist", payload);
        },
        delete: async (payload: PostPayload): Promise<Response> => {
          return await post("/delete-therapist", {
            idsArray: payload as string[],
          });
        },
        update: async (payload: PostPayload): Promise<Response> => {
          const editById = payload as UpdateType;
          delete editById.updateObj._id;
          return await post("/edit-therapist", payload);
        },
      },
      settings: {
        public: {
          get: async (): Promise<Response> => {
            return await get("/retrieve-public-settings");
          },
        },
        admin: {
          get: async (): Promise<Response> => {
            return await get("/retrieve-settings");
          },
        },
      },
      configuration: {
        classes: {
          get: async (): Promise<Response> => {
            return await get("/configuration/classes");
          },

          add: async (payload: PostPayload): Promise<Response> => {
            return await post("/configuration/classes", payload);
          },

          update: async (
            payload: PostPayload,
            classId: string
          ): Promise<Response> => {
            return await post(`/configuration/classes/${classId}`, payload);
          },

          delete: async (classId: string): Promise<Response> => {
            return await _delete(`/configuration/classes/${classId}`);
          },

          grades: {
            add: async (
              payload: PostPayload,
              classId: string
            ): Promise<Response> => {
              return await post(
                `/configuration/classes/${classId}/grades`,
                payload
              );
            },

            update: async (
              payload: PostPayload,
              classId: string,
              gradeId: string
            ) => {
              return await post(
                `/configuration/classes/${classId}/grades/${gradeId}`,
                payload
              );
            },

            delete: async (classId: string, gradeId: string) => {
              return await _delete(
                `/configuration/classes/${classId}/grades/${gradeId}`
              );
            },
          },

          workTypes: {
            add: async (
              payload: PostPayload,
              classId: string
            ): Promise<Response> => {
              return await post(
                `/configuration/classes/${classId}/work-types`,
                payload
              );
            },

            update: async (
              payload: PostPayload,
              classId: string,
              workTypeId: string
            ) => {
              return await post(
                `/configuration/classes/${classId}/work-types/${workTypeId}`,
                payload
              );
            },

            delete: async (classId: string, workTypeId: string) => {
              return await _delete(
                `/configuration/classes/${classId}/work-types/${workTypeId}`
              );
            },
          },
        },
      },
      serviceRecord: {
        get: async (params: { id: string }) => {
          const { data } = await unwrapResponse<{ data: ServiceRecord }>(
            send({
              method: "GET",
              endpoint: `/service-records/${params.id}`,
            })
          );
          return data;
        },
        add: async (params: CreateServiceRecord) => {
          assertValid(serviceRecordValidators.validateCreate, params);

          const { data } = await unwrapResponse<{ data: ServiceRecord }>(
            send({
              method: "POST",
              endpoint: "/service-records",
              body: params,
            })
          );
          return data;
        },
        delete: async (params: { id: string }) => {
          await unwrapEmptyResponse(
            send({
              method: "DELETE",
              endpoint: `/service-records/${params.id}`,
            })
          );
        },
        update: async (params: { id: string; update: UpdateServiceRecord }) => {
          console.log("update before validation:", params.update);
          assertValid(serviceRecordValidators.validateUpdate, params.update);
          console.log("update after validation:", params.update);

          const { data } = await unwrapResponse<{ data: ServiceRecord }>(
            send({
              method: "PUT",
              endpoint: `/service-records/${params.id}`,
              body: params.update,
            })
          );
          return data;
        },
        list: Object.assign(
          async (params: ListServiceRecords) => {
            assertValid(serviceRecordValidators.validateList, params);

            const { data } = await unwrapResponse<{ data: ServiceRecord[] }>(
              send({
                method: "GET",
                endpoint: `/service-records?${QueryString.stringify(params)}`,
              })
            );
            return data;
          },
          {
            queryKey: (params: ListServiceRecords) => [
              "serviceRecords",
              "list",
              params,
            ],
          }
        ),
      },
      invoice: {
        get: async (payload: GetPayload): Promise<Response> => {
          return await get(
            `/generate-invoice?${serializeQueryParams(payload)}`
          );
        },
        list: Object.assign(
          async (): Promise<IInvoiceObject[]> => {
            type InvoiceObject = {
              _id: string;
              json: {
                metadata: {
                  [key: string]: string;
                };
              };
            };

            const res = await get("/get-invoice-list");
            if (isContentJSON(res)) {
              const invoiceList = await res.json();
              return invoiceList.map((i: InvoiceObject) => {
                const metadata = i.json.metadata;
                return {
                  classification: metadata.classification,
                  invoiceDate: metadata.invoiceDate,
                  schoolId: metadata.schoolId,
                  invoiceNumber: metadata.invoiceIdentifier,
                  _id: i._id,
                };
              });
            } else {
              throw new Error("Invalid response to get-invoice-list");
            }
          },
          { queryKey: () => ["invoice", "list"] }
        ),
        delete: async (params: GetPayload): Promise<Response> => {
          return await _delete(
            `/revert-invoice?${serializeQueryParams(params)}`
          );
        },
        add: async (payload: PostPayload): Promise<Response> => {
          return await post("/post-invoice-object", payload);
        },
        number: {
          get: async (payload: GetPayload): Promise<Response> => {
            return await get(
              `/next-invoiceid?${serializeQueryParams(payload)}`
            );
          },
          isUnique: async (payload: GetPayload): Promise<Response> => {
            return await get(
              `/invoice-no-unique?${serializeQueryParams(payload)}`
            );
          },
          increment: async (payload: PostPayload): Promise<Response> => {
            return await post(`/increment-invoiceid`, payload);
          },
        },
        file: {
          pdf: {
            get: async (params: GetPayload): Promise<Response> => {
              return await get(
                `/get-invoice-byid?${serializeQueryParams(params)}`
              );
            },
          },
          csv: {
            get: async (params: PostPayload): Promise<Response> => {
              return await post(`/export-invoice-to-quickbooks`, params);
            },
          },
        },
      },
      timesheet: {
        list: async (): Promise<Response> => {
          return await get("/retrieve-timesheet");
        },
        update: async (payload: PostPayload): Promise<Response> => {
          const editById = payload as UpdateType;
          delete editById.updateObj._id;
          return await post("/edit-timesheet", payload);
        },
        add: async (payload: PostPayload): Promise<Response> => {
          return await post("/store-timesheet", payload);
        },
        delete: async (payload: PostPayload): Promise<Response> => {
          return await post("/delete-timesheet", payload);
        },
        deadline: {
          get: async (): Promise<Response> => {
            return await get("/timesheet-deadline");
          },
        },
      },
      intervention: {
        group: {
          therapist: {
            get: Object.assign(
              async ({ id }: { id: string }) => {
                const { data } = await unwrapResponse<{
                  data: PopulatedInterventionGroup[];
                }>(
                  send({
                    method: "GET",
                    endpoint: `/interventions/groups/therapist/${id}`,
                  })
                );

                return data;
              },
              {
                queryKey: (params: { id: string }) => [
                  "intervention",
                  "group",
                  "therapist",
                  "get",
                  params,
                ],
              }
            ),
          },
          class: {
            get: Object.assign(
              async ({ classId }: { classId: string }) => {
                const { data } = await unwrapResponse<{
                  data: PopulatedInterventionGroup[];
                }>(
                  send({
                    method: "GET",
                    endpoint: `/interventions/groups/class/${classId}`,
                  })
                );

                return data;
              },
              {
                queryKey: (params: { classId: string }) => [
                  "intervention",
                  "group",
                  "class",
                  "get",
                  params,
                ],
              }
            ),
          },
          add: async (obj: CreateInterventionsGroup): Promise<Response> => {
            assertValid(interventionGroupsValidators.validateCreate, obj);
            return await send({
              method: "POST",
              endpoint: "/interventions/groups",
              body: obj,
            });
          },
          list: Object.assign(
            async (): Promise<InterventionGroup[]> => {
              const { data } = await unwrapResponse<{
                data: InterventionGroup[];
              }>(
                send({
                  method: "GET",
                  endpoint: "/interventions/groups",
                })
              );
              return data;
            },
            { queryKey: ["interventions", "group", "list"] }
          ),
          update: async ({
            id,
            update,
          }: {
            id: string;
            update: UpdateInterventionsGroup;
          }) => {
            assertValid(interventionGroupsValidators.validateUpdate, update);

            return await send({
              method: "PUT",
              endpoint: `/interventions/groups/${id}`,
              body: update,
            });
          },
          delete: async ({ id }: DeleteInterventionsGroup) => {
            return await send({
              method: "DELETE",
              endpoint: `/interventions/groups/${id}`,
            });
          },
        },
        get: Object.assign(
          async (params: { id: string }) => {
            const { data } = await unwrapResponse<{ data: Intervention }>(
              send({
                method: "GET",
                endpoint: `/interventions/${params.id}`,
              })
            );

            return data;
          },
          {
            queryKey: (params: { id: string }) => ["interventions", params],
          }
        ),
        add: async (obj: CreateIntervention): Promise<Response> => {
          assertValid(interventionValidators.validateCreate, obj);
          return await send({
            method: "POST",
            endpoint: "/interventions",
            body: obj,
          });
        },
        list: Object.assign(
          async (): Promise<Intervention[]> => {
            const { data } = await unwrapResponse<{
              data: Intervention[];
            }>(
              send({
                method: "GET",
                endpoint: "/interventions",
              })
            );
            return data;
          },
          { queryKey: ["interventions", "list"] }
        ),
        delete: async ({ id }: DeleteIntervention) => {
          return await send({
            method: "DELETE",
            endpoint: `/interventions/${id}`,
          });
        },
      },
      exports: {
        studentServiceDetails: {
          csv: async (body: RetrieveStudentServiceDetails): Promise<string> => {
            const response = await send({
              method: "POST",
              body,
              endpoint: "/exports/student-service-details",
            });

            const content = await response.json();

            if (!response.ok) {
              throw new Error(content.error.message);
            }
            return content.data;
          },
        },
      },
      student: {
        list: Object.assign(
          async (params: ListStudents) => {
            assertValid(studentValidators.validateList, params);

            const { data } = await unwrapResponse<{ data: Student[] }>(
              send({
                method: "GET",
                endpoint: `/students?${QueryString.stringify(params)}`,
              })
            );
            return data;
          },
          {
            queryKey: (params: ListStudents) => ["students", "list", params],
          }
        ),
      },
    }),
    [_delete, get, post, send]
  );

  return api;
}

export async function unwrapEmptyResponse(pendingResponse: Promise<Response>) {
  const response = await pendingResponse;

  if (!response.ok) {
    handleErrorMessage(
      response.body ? await response.json() : undefined,
      response.statusText
    );
  }
}

export async function unwrapResponse<T>(
  pendingResponse: Promise<Response>
): Promise<T> {
  const response = await pendingResponse;
  if (!response.body) {
    throw Error("Response had no body");
  }

  const responseJson = await response.json();

  if (!response.ok) {
    handleErrorMessage(responseJson, response.statusText);
  }

  return responseJson;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleErrorMessage(response: any, fallback: string) {
  console.error("API Error", {
    url: response.url,
    status: response.statusText,
    body: response,
  });

  if ("message" in response) {
    throw Error(response.message);
  } else if ("error" in response && "message" in response.error) {
    throw Error(response.error.message);
  } else if ("errors" in response) {
    throw Error(response.errors[0].message);
  } else if (Object.keys(response).length > 0) {
    throw Error(response);
  } else {
    throw Error(fallback);
  }
}

function assertValid<T>(validator: ValidateFunction<T>, value: unknown) {
  if (!validator(value)) {
    console.error("Validation failed", validator.errors);
    const error = validator.errors?.[0];
    throw Error(
      error ? `${error.instancePath} ${error.message}` : "Validation failed"
    );
  }
}
