import React from "react";

import { IInvoiceObject } from "../common/interfaces";
import { ArrowDownwardIcon, DeleteIcon, ExitToAppIcon } from "../icons";
import { DateTime } from "luxon";
import { SchoolContext } from "../context/SchoolContext";
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@material-ui/data-grid";
import { useDataGridStyles } from "../hooks/useDatagridStyles";
import {
  CircularProgress,
  IconButton,
  LinearProgress,
} from "@material-ui/core";

export default function InvoiceTable({
  invoices,
  onDownload,
  onExport,
  onDelete,
  isLoading,
}: {
  invoices: IInvoiceObject[];
  onDownload: (inv: IInvoiceObject) => void;
  onExport: (inv: IInvoiceObject) => void;
  onDelete: (inv: IInvoiceObject) => void;
  isLoading: boolean;
}): JSX.Element {
  const { schoolsById } = React.useContext(SchoolContext);

  const tableRows = invoices.map((invoice) => ({
    id: invoice._id,
    invoiceNumber: invoice.invoiceNumber,
    invoiceDate: DateTime.fromFormat(invoice.invoiceDate, "LL/dd/yyyy"),
    school: schoolsById[invoice.schoolId]?.name ?? null,
    service: invoice.classification,
    invoice,
  }));

  const tableColumns: GridColDef[] = [
    {
      headerName: "Number",
      field: "invoiceNumber",
      flex: 1,
    },
    {
      headerName: "Date",
      field: "invoiceDate",
      flex: 1,
      renderCell: ({ row }) =>
        (row.invoiceDate as DateTime).toLocaleString(DateTime.DATE_SHORT),
    },
    {
      headerName: "School",
      field: "school",
      flex: 1,
      renderCell: ({ row }) => {
        const school = row.school as string;
        if (school === null) {
          return <LinearProgress style={{ width: "100%" }} />;
        } else {
          return school;
        }
      },
    },
    { headerName: "Service", field: "service", flex: 1 },
    {
      headerName: "Action",
      field: "invoice",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => (
        <div>
          <IconButton size="small" onClick={() => onDownload(row.invoice)}>
            <ArrowDownwardIcon key={"download"} fontSize="small" />
          </IconButton>
          <IconButton size="small" onClick={() => onExport(row.invoice)}>
            <ExitToAppIcon key={"export"} fontSize="small" />
          </IconButton>
          <IconButton size="small" onClick={() => onDelete(row.invoice)}>
            <DeleteIcon key={"delete"} fontSize="small" />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <DataGrid
      className={useDataGridStyles().className}
      style={{ height: "77dvh" }}
      columns={tableColumns}
      rows={tableRows}
      loading={isLoading}
      components={{
        LoadingOverlay: () => (
          <GridOverlay>
            <CircularProgress size={70} />
          </GridOverlay>
        ),
        Toolbar: () => (
          <GridToolbarContainer>
            <GridToolbarFilterButton />
          </GridToolbarContainer>
        ),
      }}
      autoHeight={false}
      disableSelectionOnClick
      disableColumnMenu
    />
  );
}
