import { Button, Paper, TextField, Typography } from "@material-ui/core";
import React from "react";
import {
  DropdownSelectorOption,
  IGrade,
  IWorkType,
} from "../../common/interfaces";
import { AlternateID, OverrideScript } from "../../common/Script";
import Toaster, { ToastType } from "../../common/Toaster";
import { DeleteIcon } from "../../icons";
import { SimpleActionTable } from "../Display/Table/SimpleActionTable";
import DropdownSelect, { makeDropdownOptions } from "./DropdownSelect";
import useModal from "../../hooks/useModal";
import HeaderWithRightSection from "../Display/HeaderWithRightSection";
import Column from "../Display/Column";
import Row from "../Display/Row";
import {
  MultiCheckboxInput,
  MultiCheckboxInputConfig,
  MultiCheckboxState,
} from "./MultiCheckboxForm";

export interface NewWorkTypeInputProps extends Partial<HTMLElement> {
  types: { [uuid: string]: IWorkType };
  grades: { [uuid: string]: IGrade };
  addType: (type: IWorkType) => void;
  removeType: (id: string) => void;
}

export function NewWorkTypeInput(props: NewWorkTypeInputProps): JSX.Element {
  const { types, grades, addType, removeType } = props;

  const { modal: NewWorkTypeModal, toggle } = useModal();

  const [newTypeName, setNewTypeName] = React.useState<string>("");
  const [newTypeGradeId, setNewTypeGrade] = React.useState<string>("");

  const [currentAlternate] = React.useState<AlternateID>({
    alternate: "",
    index: -1,
  });
  const [error, setError] = React.useState<boolean>(false);

  const onNameInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setError(false);
      setNewTypeName(event.target.value);
    },
    [setNewTypeName]
  );

  const onGradeInputChange = React.useCallback(
    (option: DropdownSelectorOption) => {
      setError(false);
      setNewTypeGrade(option.value);
    },
    [setNewTypeGrade]
  );

  const sortedWorkTypes = React.useMemo(() => {
    return Object.entries(types)
      .sort(([, a], [, b]) => a.name.localeCompare(b.name))
      .map(([id, type]) => ({ id, type }));
  }, [types]);

  const sortedGrades = React.useMemo(() => {
    return Object.entries(grades).sort(([, a], [, b]) =>
      a.name.localeCompare(b.name)
    );
  }, [grades]);

  const sortedGradeOptions = React.useMemo(() => {
    return makeDropdownOptions(
      sortedGrades.map(([id, grade]) => ({ id, name: grade.name })),
      "name",
      "id"
    );
  }, [sortedGrades]);

  const overrideScript: OverrideScript<{ id: string; type: IWorkType }> = [
    {
      id: "name",
      text: "Type Name",
      getField: ({ type }) => type.name,
    },
    {
      id: "grade",
      text: "Grade",
      getField: ({ type }) =>
        type.grade in grades ? grades[type.grade].name : "",
    },
    {
      id: "requireStudents",
      text: "Student(s)",
      align: "right",
      getField: ({ type }) => (type.requireStudents ? "Yes" : "No"),
    },
    {
      id: "requireStartTime",
      text: "Start Time",
      align: "right",
      getField: ({ type }) => (type.requireStartTime ? "Yes" : "No"),
    },
    {
      id: "requireIntervention",
      text: "Intervention(s)",
      align: "right",
      getField: ({ type }) => (type.requireIntervention ? "Yes" : "No"),
    },
    {
      id: "requireNarrativeNote",
      text: "Narrative Note",
      align: "right",
      getField: ({ type }) => (type.requireNarrativeNote ? "Yes" : "No"),
    },
    {
      id: "action",
      text: "Action",
      isAction: true,
      align: "right",
      action: ({ id }) => removeType(id),
      actionIcon: <DeleteIcon key="delete" fontSize="small" />,
    },
  ];

  const defaultCheckboxState: MultiCheckboxState = {
    requireStudents: false,
    requireNarrativeNote: false,
    requireIntervention: false,
    requireStartTime: false,
  };

  const [multiCheckBoxState, setMultiCheckboxState] =
    React.useState<MultiCheckboxState>(defaultCheckboxState);

  const checkBoxConfig: MultiCheckboxInputConfig = {
    rows: [
      {
        label: "Student(s)",
        value: "requireStudents",
      },
      {
        label: "Start Time",
        value: "requireStartTime",
      },
      {
        label: "Intervention(s)",
        value: "requireIntervention",
      },
      {
        label: "Narrative Note",
        value: "requireNarrativeNote",
      },
    ],
    state: multiCheckBoxState,
    setState: setMultiCheckboxState,
  };

  const resetNewType = () => {
    setNewTypeGrade("");
    setNewTypeName("");
    setMultiCheckboxState(defaultCheckboxState);
  };

  return (
    <Paper style={{ width: "100%" }}>
      <SimpleActionTable
        population={sortedWorkTypes}
        title={"Work Types"}
        action={({ id, type }) => {
          if (!type) return;
          removeType(id);
        }}
        actionIcon={<DeleteIcon />}
        makeDisplay={({ type }) => type.name}
        overrideScript={overrideScript}
        currentAlternate={currentAlternate}
        tableContainerProps={{
          height: "400px",
          overflowY: "auto",
        }}
      />
      <HeaderWithRightSection
        leftSection={<></>}
        rightSection={
          <Button
            style={{ margin: "5px" }}
            color="primary"
            variant="outlined"
            onClick={toggle}
          >
            New work type
          </Button>
        }
      />
      <NewWorkTypeModal>
        <Column gap="l" style={{ padding: "10px" }}>
          <TextField
            variant="outlined"
            label="Type Name"
            value={newTypeName}
            onChange={onNameInputChange}
            error={error}
          />
          <Row gap="l">
            <DropdownSelect
              forcePlaceholder
              value={{
                label:
                  newTypeGradeId in grades ? grades[newTypeGradeId].name : "",
                value: newTypeGradeId,
              }}
              handleChange={(ns) => {
                if (ns) onGradeInputChange(ns as DropdownSelectorOption);
              }}
              label={"Grade"}
              isSearchable={false}
              options={sortedGradeOptions}
            />
          </Row>
          <Typography variant="h6" style={{ marginBottom: "-10px" }}>
            Configure Requirements
          </Typography>
          <MultiCheckboxInput {...checkBoxConfig} />
          <Row gap="l">
            <Button
              onClick={() => {
                toggle();
                resetNewType();
              }}
              fullWidth
              variant="outlined"
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (
                  Object.values(types)
                    .map((t) => t.name)
                    .includes(newTypeName)
                ) {
                  Toaster(
                    `Type '${newTypeName}' cannot be duplicated.`,
                    ToastType.error
                  );
                  return;
                }
                addType({
                  name: newTypeName,
                  grade: newTypeGradeId,
                  requireIntervention: multiCheckBoxState.requireIntervention,
                  requireStudents: multiCheckBoxState.requireStudents,
                  requireNarrativeNote: multiCheckBoxState.requireNarrativeNote,
                  requireStartTime: multiCheckBoxState.requireStartTime,
                });
                resetNewType();
                toggle();
              }}
              fullWidth
              variant="contained"
              color="primary"
            >
              Add
            </Button>
          </Row>
        </Column>
      </NewWorkTypeModal>
    </Paper>
  );
}
