import React from "react";
import { ISchool } from "../common/interfaces";
import { CircularProgress, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { SchoolContext } from "../context/SchoolContext";
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@material-ui/data-grid";
import { useDataGridStyles } from "../hooks/useDatagridStyles";

export default function SchoolTable({
  onEdit,
  onDelete,
}: {
  onEdit: (school: ISchool) => void;
  onDelete: (school: ISchool) => void;
}) {
  const { schools, isLoading } = React.useContext(SchoolContext);

  const tableRows = schools.map((school) => ({
    id: school.id,
    name: school.name,
    address: school.address,
    invoicePrefix: school.invoicePrefix,
    numStudents: school.students.length,
    school,
  }));

  const tableColumns: GridColDef[] = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
    },
    {
      headerName: "Address",
      field: "address",
      flex: 1,
    },
    {
      headerName: "Invoice Prefix",
      field: "invoicePrefix",
      width: 150,
    },
    {
      headerName: "Students",
      field: "numStudents",
      width: 130,
    },
    {
      headerName: "Action",
      field: "school",
      sortable: false,
      filterable: false,
      width: 100,
      renderCell: ({ row }) => (
        <div>
          <IconButton size="small" onClick={() => onEdit(row.school)}>
            <EditIcon key={"edit"} fontSize="small" />
          </IconButton>
          <IconButton size="small" onClick={() => onDelete(row.school)}>
            <DeleteIcon key={"delete"} fontSize="small" />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <DataGrid
      className={useDataGridStyles().className}
      style={{ height: "82vh" }}
      columns={tableColumns}
      rows={tableRows}
      components={{
        LoadingOverlay: () => (
          <GridOverlay>
            <CircularProgress size={70} />
          </GridOverlay>
        ),
        Toolbar: () => (
          <GridToolbarContainer>
            <GridToolbarFilterButton />
          </GridToolbarContainer>
        ),
      }}
      loading={isLoading}
      disableSelectionOnClick
      disableColumnMenu
    />
  );
}
