import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import React from "react";
import { FileCopy } from "@material-ui/icons";
import { Beenhere } from "@material-ui/icons";

export default function PageTabs({
  selectedPage,
  setSelectedPage,
}: {
  selectedPage: string;
  setSelectedPage: (page: string) => void;
}) {
  const mainListItems = React.useMemo(
    () => (
      <div>
        <ListItem
          button
          selected={selectedPage === "/"}
          component={Link}
          to={"/"}
          onClick={() => setSelectedPage("/")}
        >
          <ListItemIcon>
            <FileCopy />
          </ListItemIcon>
          <ListItemText primary="Logging" />
        </ListItem>
        <ListItem
          button
          selected={selectedPage === "/progress"}
          component={Link}
          to={"/progress"}
          onClick={() => setSelectedPage("/progress")}
        >
          <ListItemIcon>
            <Beenhere />
          </ListItemIcon>
          <ListItemText primary="Progress Tracker" />
        </ListItem>
      </div>
    ),
    [selectedPage, setSelectedPage]
  );
  return mainListItems;
}
