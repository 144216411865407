import { IconButton, Paper } from "@material-ui/core";
import React from "react";
import { IClassification, ServicesContextType } from "../common/interfaces";
import { ServicesContext } from "../context/ServicesContext";
import ClassificationTable from "./ClassificationTable";
import ConfirmationModal from "./ConfirmationModal";
import ClassificationEditModal, {
  EditEvent,
  AddGrade,
  AddWorkType,
} from "./ClassificationEditModal";
import Loading from "./loading";
import { isObjectEmpty } from "../common/Utils";
import Toaster, { ToastType } from "../common/Toaster";
import { useHistory } from "react-router-dom";
import SectionHeaderText from "./Display/SectionHeaderText";
import { AddIcon } from "../icons";

export default function ClassificationEditor(): JSX.Element {
  const settings: ServicesContextType = React.useContext(ServicesContext);
  const {
    syncSettingsWithServer,
    addClass,
    updateClass,
    removeClass,
    addGradeToClass,
    updateGradeInClass,
    removeGradeFromClass,
    addWorkTypeToClass,
    removeWorkTypeFromClass,
    triggerRefresh,
    classes,
  } = settings;
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    React.useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = React.useState<boolean>(false);
  const [selectedClassId, setSelectedClassId] = React.useState<string>();
  const [classIdToDelete, setClassIdToDelete] = React.useState<string>();
  const history = useHistory();

  const clearEditing = React.useCallback(() => {
    setSelectedClassId(undefined);
    setEditModalOpen(false);
  }, []);

  const handleEdit = React.useCallback(
    (id: string) => {
      history.push(`/services/${id}`);
    },
    [history]
  );

  async function handleEventsConcurrently(
    editEvents: Map<string, EditEvent>,
    handler: (value: [string, EditEvent]) => Promise<unknown>
  ) {
    return Promise.all(Array.from(editEvents).map(handler));
  }

  const handleSave = React.useCallback(
    async (newClass: IClassification, editEvents: Map<string, EditEvent>) => {
      const creatingClass = selectedClassId === undefined;

      const classId =
        (creatingClass
          ? await addClass?.({
              name: newClass.name,
              special: newClass.special,
            }).then((res) => {
              console.log("ADDED CLASS UUID", res.uuid);
              return res.uuid;
            })
          : selectedClassId) ?? "";

      try {
        await handleEventsConcurrently(editEvents, async ([id, event]) => {
          switch (event.kind) {
            case "AddGrade": {
              // Work types within edit events reference a grade by id. If this grade was created during
              // this edit session, its id was locally generated so referencing it from the backend will
              // produce an error.
              // To handle this correctly, we push all grades to the backend first and record their
              // backend-generated id. Later, when we handle work type events, we'll update their stored
              // grade ids appropriately.
              event.backendId =
                (await addGradeToClass?.(event.grade, classId).then(
                  (res) => res.uuid
                )) ?? "";

              return;
            }
            case "EditGrade": {
              updateGradeInClass?.(event.grade, classId, event.backendId);
              return;
            }
            case "RemoveWorkType": {
              // A grade cannot be removed until all associated work types have been removed first.
              // We remove work types early so that users can remove both a grade and its associated
              // work types in a single edit event.
              await removeWorkTypeFromClass?.(classId, id);
              return;
            }
          }
        });

        const updateWorkTypeToUseBackendGradeId = (event: AddWorkType) => {
          const dependee = editEvents.get(event.workType.grade) as
            | AddGrade
            | undefined;

          if (dependee !== undefined) {
            event.workType.grade = dependee.backendId ?? "";
          }
        };

        await handleEventsConcurrently(editEvents, async ([id, event]) => {
          switch (event.kind) {
            case "RemoveGrade": {
              return removeGradeFromClass?.(classId, id);
            }
            case "AddWorkType": {
              updateWorkTypeToUseBackendGradeId(event);
              return addWorkTypeToClass?.(event.workType, classId);
            }
            case "UpdateClassName": {
              return updateClass?.({ name: event.name }, classId);
            }
          }
        });
      } catch (reason) {
        await syncSettingsWithServer?.();
        Toaster(`Failed to save changes. ${reason}`, ToastType.error);
        return;
      }

      syncSettingsWithServer?.();
      clearEditing();
      Toaster(
        `Classification ${creatingClass ? "created" : "saved"} successfully.`,
        ToastType.good
      );
      setEditModalOpen(false);
      triggerRefresh();
    },
    [
      selectedClassId,
      addClass,
      syncSettingsWithServer,
      clearEditing,
      triggerRefresh,
      addGradeToClass,
      updateGradeInClass,
      removeWorkTypeFromClass,
      removeGradeFromClass,
      addWorkTypeToClass,
      updateClass,
    ]
  );

  const handleExitEdit = React.useCallback(() => {
    clearEditing();
  }, [clearEditing]);

  const handleDelete = React.useCallback(
    (id: string) => {
      setClassIdToDelete(id);
      setDeleteConfirmationModalOpen(true);
    },
    [setClassIdToDelete, setDeleteConfirmationModalOpen]
  );

  const deleteClassification = React.useCallback(async () => {
    setDeleteConfirmationModalOpen(false);
    if (classIdToDelete === undefined) return;

    try {
      await removeClass?.(classIdToDelete);
    } catch (reason) {
      Toaster(`Failed to delete class. ${reason}`, ToastType.error);
      return;
    }

    await syncSettingsWithServer?.();

    setClassIdToDelete(undefined);
  }, [
    syncSettingsWithServer,
    setDeleteConfirmationModalOpen,
    classIdToDelete,
    removeClass,
    setClassIdToDelete,
  ]);

  const nameIsSafe = React.useCallback(
    (name: string) => {
      return !Object.values(classes)
        .map((clss) => clss.name)
        .includes(name);
    },
    [classes]
  );

  const isClassReady = React.useCallback(
    (id: string) => !isObjectEmpty(classes) && id in classes,
    [classes]
  );

  if (settings.isLoading) return <Loading />;
  return (
    <Paper style={{ padding: "10px", height: "auto" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <SectionHeaderText>Services</SectionHeaderText>
        <IconButton
          color="primary"
          onClick={async () => {
            const res = await addClass?.({
              name: "New service",
            });

            if (!res?.uuid) {
              Toaster("Failed to create new service.");
              return;
            }

            triggerRefresh();
            history.push(`/services/${res.uuid}`);
          }}
        >
          <AddIcon />
        </IconButton>
      </div>

      <ClassificationTable
        classifications={classes}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />

      <ConfirmationModal
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        onConfirm={() => deleteClassification()}
        onCancel={() => setDeleteConfirmationModalOpen(false)}
        message={`Are you sure want to delete ${
          classIdToDelete !== undefined && isClassReady(classIdToDelete)
            ? classes[classIdToDelete].name
            : ""
        }?`}
      />
      <ClassificationEditModal
        uuid={selectedClassId}
        open={editModalOpen}
        setOpen={setEditModalOpen}
        handleExit={handleExitEdit}
        classification={
          selectedClassId !== undefined && isClassReady(selectedClassId)
            ? classes[selectedClassId]
            : undefined
        }
        handleSave={handleSave}
        safeName={nameIsSafe}
      />
    </Paper>
  );
}
