import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { ISchool } from "../../common/interfaces";
import { clsx } from "clsx";
import { DateTime } from "luxon";
import { ServiceRecord } from "../../api/schemas/service-records/schema";
export interface WorkItemSummaryPaneProps {
  selectedSchool: ISchool | undefined;
  selectedTherapist: string;
  workItems: ServiceRecord[];
  start: DateTime;
  end: DateTime;
  numSchools: number;
}

const useStyles = makeStyles({
  paper: {
    padding: "20px",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  totals: {
    padding: "auto",
  },
  dimmed: {
    color: "rgba(0, 0, 0, 0.54)",
  },
});

export function WorkItemSummaryPane(
  props: WorkItemSummaryPaneProps
): JSX.Element {
  const {
    selectedSchool,
    selectedTherapist,
    workItems,
    start,
    end,
    numSchools,
  } = props;
  const styles = useStyles();

  const totalUnits =
    workItems.length > 0
      ? workItems
          .map((workItem) => workItem.units)
          .reduce((prev, current) => prev + current, 0)
      : 0;
  const totalHours = totalUnits / 4;

  return (
    <Grid item xs={6}>
      <Paper
        className={clsx(styles.paper, styles.totals)}
        style={{ height: "120px" }}
      >
        <Typography variant="h5" component="h6">
          Total for
          {selectedSchool
            ? ` ${selectedSchool.name} ${selectedSchool.invoicePrefix}`
            : ` All School Districts (${numSchools})`}
          {selectedTherapist === "All" ? "" : ` > ${selectedTherapist}`}
        </Typography>
        <Typography variant="body2" className={styles.dimmed}>
          {start.toFormat("EEE MMM d yyyy")} to {end.toFormat("EEE MMM d yyyy")}
        </Typography>
        <Typography variant="subtitle1">
          {totalUnits} Units, {totalHours} Hours
        </Typography>
      </Paper>
    </Grid>
  );
}
