import { FormControlLabel, Switch } from "@material-ui/core";
import React from "react";

interface ShowInvoicedToggleProps {
  toggle: () => void;
  showInvoiced: boolean;
}

export default function ShowInvoicedSwitch(
  props: ShowInvoicedToggleProps
): JSX.Element {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={props.showInvoiced}
          onChange={props.toggle}
          color="primary"
        />
      }
      label="Show Invoiced"
    />
  );
}
