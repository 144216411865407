import React from "react";
import {
  ISchool,
  ISchoolContext,
  ISchoolContextProps,
} from "../common/interfaces";
import { useIsMounted } from "../common/useIsMounted";
import { createLogger } from "../components/Logging/Logging";
import { useApi } from "../api/useApi";
import { isContentJSON, isContentText } from "../common/Utils";

const defaultState: ISchoolContext = {
  schools: [],
  schoolsById: {},
  isLoading: true,
  makeDirty: () => null,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type StudentObject = {
  name: string;
  sasid: string;
};
type ProgramObject = {
  name: string;
  programId: string;
};
type SchoolObject = {
  _id: string;
  name: string;
  address: string;
  students: StudentObject[];
  invoicePrefix: string;
  programs: ProgramObject[];
};

export const SchoolContext = React.createContext<ISchoolContext>(defaultState);

const logger = createLogger("SchoolContext");

export function SchoolContextProvider({
  children,
}: ISchoolContextProps): JSX.Element {
  const isMounted = useIsMounted();
  const api = useApi();

  const [refreshTrigger, setRefreshTrigger] = React.useState<number>(0);
  const [state, setState] = React.useState<ISchoolContext>({
    ...defaultState,
    makeDirty: () => setRefreshTrigger((prev) => prev + 1),
  });

  React.useEffect(() => {
    api.school
      .list()
      .then((res) => {
        if (isContentJSON(res)) {
          res.json().then((j) => {
            if (res.status === 200) {
              const schools: ISchool[] = j.map((i: SchoolObject) => ({
                id: i._id,
                name: i.name,
                address: i.address,
                students: i.students.map((is: StudentObject) => ({
                  name: is.name,
                  sasid: String(is.sasid),
                })),
                invoicePrefix: i.invoicePrefix ?? "",
                programs: i.programs?.map((pg: ProgramObject) => ({
                  name: pg.name,
                  programId: pg.programId,
                })),
              }));
              const schoolsById = Object.fromEntries(
                schools.map((school: ISchool) => [school.id, school])
              );
              if (isMounted.current)
                setState((prev) => ({
                  ...prev,
                  isLoading: false,
                  schools,
                  schoolsById,
                }));
            } else {
              logger.error(
                `ERROR Code ${res.status}: ${res.statusText} => ${j.message}`,
                { res, j }
              );
            }
          });
        } else if (isContentText(res)) {
          res.text().then((text) => {
            console.log(text);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [isMounted, refreshTrigger, api.school]);

  React.useEffect(() => {
    logger.info(`loaded = ${!state.isLoading}`);
  }, [state.isLoading]);

  return (
    <SchoolContext.Provider value={state}>{children}</SchoolContext.Provider>
  );
}

export default SchoolContextProvider;
