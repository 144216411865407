import React, { CSSProperties, useContext, useState } from "react";
import { ServiceRecord } from "../api/schemas/service-records/schema";
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarExport,
} from "@material-ui/data-grid";
import {
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@material-ui/core";
import HeaderWithRightSection from "./Display/HeaderWithRightSection";
import Row from "./Display/Row";
import SectionHeaderText from "./Display/SectionHeaderText";
import { BoldNumberDisplay } from "./Display/BoldNumberDisplay";
import { RangeDatePicker } from "./RangeDatePicker/RangeDatePicker";
import { DateTime } from "luxon";
import ShowInvoicedSwitch from "./Input/ShowInvoicedToggle";
import { useDateRanges } from "../hooks/useDateRanges";
import { useDataGridStyles } from "../hooks/useDatagridStyles";
import { removeDuplicates, useQueriesById } from "../common/Utils";
import { useApi } from "../api/useApi";
import { SchoolContext } from "../context/SchoolContext";
import { useMutation } from "react-query";
import Toaster, { ToastType } from "../common/Toaster";
import ConfirmationModal from "./ConfirmationModal";
import {
  AssignmentTurnedInOutlinedIcon,
  DeleteIcon,
  EditIcon,
  WarningRoundedIcon,
} from "../icons";
import { SingleWorkItemEditor } from "./Input/SingleWorkItemEditor";
import {
  DropdownSelectorOption,
  ISchool,
  ITherapist,
} from "../common/interfaces";
import DropdownSelect from "./Input/DropdownSelect";
import IconText from "./Display/IconText";
import { DefaultModal } from "./Display/DefaultModal";
import WorkItemForm from "./Input/WorkItemForm";

function getUnitHours(ssrs: ServiceRecord[]) {
  return ssrs.map((ssr) => ssr.units).reduce((a, u) => a + u, 0) / 4;
}

export function DefaultSsrTableHeader({
  ssrs,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  showInvoicedSsrs,
  setShowInvoicedSsrs,
}: {
  ssrs: ServiceRecord[];
  startDate: DateTime;
  setStartDate: (time: DateTime) => void;
  endDate: DateTime;
  setEndDate: (time: DateTime) => void;
  showInvoicedSsrs: boolean;
  setShowInvoicedSsrs: (showInvoicedSsrs: boolean) => void;
}) {
  const { dateRanges } = useDateRanges();

  const totalUnitHours = getUnitHours(ssrs);

  return (
    <HeaderWithRightSection
      style={{ margin: "2px" }}
      leftSection={
        <Row>
          <SectionHeaderText>Service Records</SectionHeaderText>
          <BoldNumberDisplay
            label={"Units"}
            num={totalUnitHours}
            unit={"Hours"}
            variant={"h4"}
            compact
          />
        </Row>
      }
      rightSection={
        <Row gap="m">
          <RangeDatePicker
            start={startDate}
            setStart={setStartDate}
            end={endDate}
            setEnd={setEndDate}
            predefinedOptions={Object.values(dateRanges)}
          />
          <ShowInvoicedSwitch
            toggle={() => setShowInvoicedSsrs(!showInvoicedSsrs)}
            showInvoiced={showInvoicedSsrs}
          />
        </Row>
      }
    />
  );
}

export function StaffViewSsrTableHeader({
  ssrs,
  invalidateQuery,
  therapist,
  showInvoicedSsrs,
  setShowInvoicedSsrs,
  school,
  setSchool,
  hideCreateSsrButton,
}: {
  ssrs: ServiceRecord[];
  invalidateQuery: () => void;
  therapist: ITherapist;
  showInvoicedSsrs: boolean;
  setShowInvoicedSsrs: (showInvoicedSsrs: boolean) => void;
  school: ISchool | undefined;
  setSchool: (school: ISchool | undefined) => void;
  hideCreateSsrButton?: boolean;
}) {
  const { schoolsById } = React.useContext(SchoolContext);
  const [showCreateSsrModal, setShowCreateSsrModal] = useState(false);

  const districtUnitHours = getUnitHours(ssrs);

  return (
    <>
      <HeaderWithRightSection
        leftSection={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 6,
            }}
          >
            <SectionHeaderText>Service Records</SectionHeaderText>
            <DropdownSelect
              style={{ width: "450px" }}
              label={"All Schools"}
              selectedOption={
                school
                  ? {
                      label: `${school.name} ${school.invoicePrefix}`,
                      value: school.id,
                    }
                  : null
              }
              handleChange={(change) => {
                const schoolId = (change as DropdownSelectorOption | null)
                  ?.value;
                setSchool(schoolId ? schoolsById[schoolId] : undefined);
              }}
              options={Object.values(schoolsById).map((school) => ({
                label: `${school.name} ${school.invoicePrefix}`,
                value: school.id,
              }))}
              forcePlaceholder
              isClearable
            />
            {!hideCreateSsrButton && (
              <Button
                variant="outlined"
                size="medium"
                color="primary"
                onClick={() => setShowCreateSsrModal(true)}
              >
                <IconText
                  variant="subtitle2"
                  icon={<AssignmentTurnedInOutlinedIcon />}
                  text={"New Service Record"}
                />
              </Button>
            )}
          </div>
        }
        rightSection={
          <Row gap="m">
            <ShowInvoicedSwitch
              toggle={() => setShowInvoicedSsrs(!showInvoicedSsrs)}
              showInvoiced={showInvoicedSsrs}
            />
            <BoldNumberDisplay
              label={"District"}
              num={districtUnitHours}
              unit={"Hours"}
              variant={"h4"}
              compact
            />
          </Row>
        }
      />
      <DefaultModal
        open={showCreateSsrModal}
        onClose={() => setShowCreateSsrModal(false)}
      >
        <WorkItemForm
          therapist={therapist}
          setOpen={setShowCreateSsrModal}
          onSuccess={invalidateQuery}
        />
      </DefaultModal>
    </>
  );
}

export function UserViewSsrTableHeader({
  ssrs,
  invalidateQuery,
  therapist,
  showInvoicedSsrs,
  setShowInvoicedSsrs,
}: {
  ssrs: ServiceRecord[];
  invalidateQuery: () => void;
  therapist: ITherapist;
  showInvoicedSsrs: boolean;
  setShowInvoicedSsrs: (showInvoicedSsrs: boolean) => void;
}) {
  const [showNewSsrModal, setShowNewSsrModal] = useState(false);

  const districtUnitHours = getUnitHours(ssrs);

  return (
    <>
      <HeaderWithRightSection
        leftSection={<SectionHeaderText>Service Records</SectionHeaderText>}
        rightSection={
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <ShowInvoicedSwitch
              toggle={() => setShowInvoicedSsrs(!showInvoicedSsrs)}
              showInvoiced={showInvoicedSsrs}
            />
            <BoldNumberDisplay
              label={"District"}
              num={districtUnitHours}
              unit={"Hours"}
              variant={"h4"}
              compact
            />
          </div>
        }
      />
      <DefaultModal
        open={showNewSsrModal}
        onClose={() => setShowNewSsrModal(false)}
      >
        <WorkItemForm
          therapist={therapist}
          setOpen={setShowNewSsrModal}
          onSuccess={invalidateQuery}
        />
      </DefaultModal>
    </>
  );
}

export function ServiceRecordTable({
  ssrs,
  header,
  isLoading,
  invalidateQuery,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  showInvoicedSsrs,
  setShowInvoicedSsrs,
  excludeColumns,
  style,
  // These were added after the table was created so we can name the CSV export.
  // selectedDistrict and selectedService are only required when enableExport
  // is true.
  selectedDistrict,
  selectedService,
  enableExport,
}: {
  ssrs: ServiceRecord[];
  header?: React.ReactNode;
  isLoading?: boolean;
  invalidateQuery: () => void;
  startDate: DateTime;
  setStartDate: (time: DateTime) => void;
  endDate: DateTime;
  setEndDate: (time: DateTime) => void;
  showInvoicedSsrs: boolean;
  setShowInvoicedSsrs: (showInvoicedSsrs: boolean) => void;
  excludeColumns?: Set<string>;
  style?: CSSProperties;
  selectedDistrict?: string;
  selectedService?: string;
  enableExport: boolean;
}) {
  const api = useApi();

  const [selectedSsr, setSelectedSsr] = useState<ServiceRecord | undefined>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const deleteSsrMutation = useMutation(api.serviceRecord.delete, {
    onSuccess: () => {
      Toaster("Service record updated", ToastType.good);
      invalidateQuery();
    },
    onError: (error: { message: string }) => {
      Toaster(
        `Failed to delete service record: ${error.message}`,
        ToastType.error
      );
    },
  });

  const therapistsById = useQueriesById({
    ids: removeDuplicates((ssrs ?? []).map((ssr) => ssr.therapistUserId)),
    api: api.therapist.get,
    idKey: "userId",
  });

  const tableRows = ssrs.map((ssr) => ({
    id: ssr._id,
    schoolName: ssr.schoolName,
    dateWorked: ssr.hasStartTime
      ? DateTime.fromISO(ssr.dateWorked)
      : DateTime.fromISO(ssr.dateWorked).endOf("day"),
    dateLogged: DateTime.fromISO(ssr.dateLogged),
    service: ssr.className,
    workType: ssr.workTypeName,
    startTime: ssr.hasStartTime
      ? DateTime.fromISO(ssr.dateWorked).toLocaleString(DateTime.TIME_SIMPLE)
      : null,
    units: ssr.units,
    students: ssr.students.map((student) => student.name).join(", "),
    therapistName: therapistsById[ssr.therapistUserId]?.name ?? null,
    invoiceDate: ssr.invoiceDate
      ? DateTime.fromFormat(ssr.invoiceDate, "MM/dd/yyyy")
      : null,
    invoiceId: ssr.invoiceId,
    ssr: ssr,
  }));

  const columnsToExclude = excludeColumns ? excludeColumns : new Set();
  const potentiallyExcluded = (col: GridColDef) =>
    columnsToExclude.has(col.headerName) ? [] : [col];

  const tableColumns = [
    ...potentiallyExcluded({
      headerName: "School",
      field: "schoolName",
      flex: 1,
    }),
    ...potentiallyExcluded({
      headerName: "Date Worked",
      field: "dateWorked",
      valueFormatter: ({ row }) =>
        (row.dateWorked as DateTime).toLocaleString(DateTime.DATE_SHORT),
      renderCell: ({ row }) =>
        (row.dateWorked as DateTime).toLocaleString(DateTime.DATE_SHORT),
      width: 140,
    }),
    ...potentiallyExcluded({
      headerName: "Date Logged",
      field: "dateLogged",
      valueFormatter: ({ row }) =>
        (row.dateLogged as DateTime).toLocaleString(DateTime.DATE_SHORT),
      renderCell: ({ row }) =>
        (row.dateLogged as DateTime).toLocaleString(DateTime.DATE_SHORT),
      width: 140,
    }),
    ...potentiallyExcluded({
      headerName: "Service",
      field: "service",
      flex: 1,
    }),
    ...potentiallyExcluded({
      headerName: "Work Type",
      field: "workType",
      flex: 1,
    }),
    ...potentiallyExcluded({
      headerName: "Start Time",
      field: "startTime",
      width: 140,
    }),
    ...potentiallyExcluded({
      headerName: "Units",
      field: "units",
      width: 90,
    }),
    ...potentiallyExcluded({
      headerName: "Students",
      field: "students",
      flex: 1,
    }),
    ...potentiallyExcluded({
      headerName: "Therapist",
      field: "therapistName",
      flex: 1,
      renderCell: ({ row }) => {
        const therapistName = row.therapistName as string | null;
        if (therapistName === null) {
          return <LinearProgress style={{ width: "100%" }} />;
        } else {
          return therapistName;
        }
      },
    }),
    ...potentiallyExcluded({
      headerName: "Invoice Date",
      field: "invoiceDate",
      hide: !showInvoicedSsrs,
      width: 140,
      valueFormatter: ({ row }) =>
        row.invoiceDate &&
        (row.invoiceDate as DateTime).toLocaleString(DateTime.DATE_SHORT),
      renderCell: ({ row }) => {
        if (row.invoiceDate !== null) {
          return (
            <Tooltip
              title={
                <div
                  style={{
                    fontSize: "18px",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                  }}
                >
                  {row.invoiceId as string}
                </div>
              }
              placement="left"
              arrow
              interactive
            >
              <div>
                {(row.invoiceDate as DateTime).toLocaleString(
                  DateTime.DATE_SHORT
                )}
              </div>
            </Tooltip>
          );
        } else {
          return undefined;
        }
      },
    }),
    ...potentiallyExcluded({
      headerName: "Action",
      field: "ssr",
      width: 100,
      sortable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <div>
          <IconButton
            size="small"
            onClick={() => {
              setSelectedSsr(row.ssr as ServiceRecord);
              setShowEditModal(true);
            }}
          >
            <EditIcon key={"edit"} fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => {
              setSelectedSsr(row.ssr as ServiceRecord);
              setShowDeleteModal(true);
            }}
          >
            <DeleteIcon key={"delete"} fontSize="small" />
          </IconButton>
        </div>
      ),
    }),
  ];

  return (
    <div style={{ width: "100%" }}>
      {header ? (
        header
      ) : (
        <DefaultSsrTableHeader
          ssrs={ssrs}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          showInvoicedSsrs={showInvoicedSsrs}
          setShowInvoicedSsrs={setShowInvoicedSsrs}
        />
      )}
      <DataGrid
        className={useDataGridStyles().className}
        style={style ? style : { marginTop: "4px", height: "50vh" }}
        columns={tableColumns}
        rows={tableRows}
        components={{
          LoadingOverlay: () => (
            <GridOverlay>
              <CircularProgress size={70} />
            </GridOverlay>
          ),
          Toolbar: () => (
            <GridToolbarContainer>
              <GridToolbarFilterButton />
              <GridToolbarColumnsButton />
              {enableExport && (
                <GridToolbarExport
                  csvOptions={{
                    fileName: `${selectedDistrict}_${selectedService}_${startDate}_${endDate}`,
                  }}
                />
              )}
            </GridToolbarContainer>
          ),
        }}
        loading={isLoading}
        disableSelectionOnClick
        disableColumnMenu
      />
      <ConfirmationModal
        message={
          <div style={{ display: "flex", alignItems: "center" }}>
            <WarningRoundedIcon
              fontSize="large"
              style={{ marginRight: "10px" }}
            />
            <Typography align="center">
              This operation is <b>irreversible!</b> Are you sure you want to
              delete this service record?
            </Typography>
          </div>
        }
        confirmText={"No, don't delete"}
        cancelText={"Yes, delete item"}
        onConfirm={() => {
          setSelectedSsr(undefined);
          setShowDeleteModal(false);
        }}
        onCancel={() => {
          selectedSsr && deleteSsrMutation.mutate({ id: selectedSsr._id });
          setSelectedSsr(undefined);
          setShowDeleteModal(false);
        }}
        open={showDeleteModal}
        setOpen={(isOpen: boolean) => setShowDeleteModal(isOpen)}
      />
      <SingleWorkItemEditor
        open={showEditModal}
        editingWorkItem={selectedSsr}
        schools={useContext(SchoolContext).schools}
        setOpen={() => {
          setShowEditModal(false);
          setSelectedSsr(undefined);
        }}
        invalidateServiceRecordQuery={invalidateQuery}
      />
    </div>
  );
}
