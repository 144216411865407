import { makeStyles } from "@material-ui/core";

export const useDataGridStyles = makeStyles({
  className: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
      display: "flex",
      alignItems: "center",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-viewport": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-overlay": {
      backgroundColor: "rgb(255, 255, 255)",
    },
  },
});
