import React from "react";
import { Checkbox, makeStyles, Typography } from "@material-ui/core";
import { clsx } from "clsx";

const useStyles = makeStyles({
  checkboxInput: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "40px",
    cursor: "pointer",
  },
  label: {
    marginTop: "10px",
    marginLeft: "10px",
    userSelect: "none",
  },
  bordered: {
    border: "1px solid lightgray",
    borderRadius: "4px",
  },
});

const CheckboxRow = (props: {
  label: string;
  isChecked: boolean;
  setIsChecked: (v: boolean) => void;
}) => {
  const styles = useStyles();
  const { isChecked, setIsChecked } = props;
  return (
    <div
      className={clsx(styles.checkboxInput)}
      onClick={() => setIsChecked(!isChecked)}
    >
      <Typography
        className={clsx(styles.label)}
        variant="body1"
        align="center"
        color="textSecondary"
      >
        {props.label}
      </Typography>
      <Checkbox checked={isChecked} name="checkedB" color="primary" />
    </div>
  );
};

const Line = () => {
  return <div style={{ borderBottom: "1px solid lightgray" }}></div>;
};

export interface MultiCheckboxState {
  [key: string]: boolean;
}

export interface MultiCheckboxInputConfig {
  rows: { label: string; value: string }[];
  state: MultiCheckboxState;
  setState: (s: MultiCheckboxState) => void;
}

export const MultiCheckboxInput = (props: MultiCheckboxInputConfig) => {
  const styles = useStyles();
  return (
    <div className={clsx(styles.bordered)}>
      {props.rows.map((row, index) => {
        return (
          <div key={row.value}>
            <CheckboxRow
              label={row.label}
              isChecked={props.state[row.value]}
              setIsChecked={(newValue: boolean) => {
                props.setState({ ...props.state, [row.value]: newValue });
              }}
            />
            {index !== props.rows.length - 1 && <Line />}
          </div>
        );
      })}
    </div>
  );
};
