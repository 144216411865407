import React from "react";
import { UpdateType, ITimesheetRecord, ITherapist } from "../common/interfaces";
import Toaster, { ToastType } from "../common/Toaster";
import { TimesheetContext } from "../context/TimesheetContext";
import {
  DeleteIcon,
  EditIcon,
  TimerOutlinedIcon,
  WarningRoundedIcon,
} from "../icons";
import {
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import UserTimesheetSubmissionFormModal from "./UserTimesheetFormModal";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import BasicDateTimePicker from "./BasicDateTimePicker";
import { GetAppSharp } from "@material-ui/icons";
import { useApi } from "../api/useApi";
import { createLogger } from "./Logging/Logging";
import { BoldNumberDisplay } from "./Display/BoldNumberDisplay";
import {
  buildTimeSheetCSV,
  downloadCSV,
  getPeriodDateStringReadable,
  isContentHTML,
  isContentJSON,
  toInterval,
} from "../common/Utils";
import { UserContext } from "../context/UserContext";
import { UnitSelector } from "./Input/UnitSelector";
import { DateTime } from "luxon";
import { formatISO } from "../common/DateUtils";
import HeaderWithRightSection from "./Display/HeaderWithRightSection";
import IconText from "./Display/IconText";
import SectionHeaderText from "./Display/SectionHeaderText";
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@material-ui/data-grid";
import { useDataGridStyles } from "../hooks/useDatagridStyles";
import ConfirmationModal from "./ConfirmationModal";
import { DefaultModal } from "./Display/DefaultModal";
import { ConfirmDenyButtonAction } from "./Input/ConfirmDenyButtonAction";

type EditingTimesheetFields = {
  clockInTime: DateTime;
  clockOutTime: DateTime;
  mileage: number;
  breakUnits: number;
};

const logger = createLogger("AdminIndividualTimesheetTable");

function getDuration(start: DateTime, end: DateTime, break_units = 0): number {
  return (
    (toInterval(start, end).toDuration("hours").toObject().hours ?? 0) -
    break_units * 0.25
  );
}

function TimesheetEditModal({
  showEditModal,
  setShowEditModal,
  editingTimesheetFields,
  setEditingTimesheetFields,
  setSelectedTimesheetId,
  handleEditTimesheetRecord,
}: {
  showEditModal: boolean;
  setShowEditModal: (showEditModal: boolean) => void;
  editingTimesheetFields: EditingTimesheetFields;
  setEditingTimesheetFields: (
    editingTimesheetFields?: EditingTimesheetFields
  ) => void;
  setSelectedTimesheetId: (selectedTimesheetId?: string) => void;
  handleEditTimesheetRecord: () => void;
}) {
  return (
    <DefaultModal onClose={() => setShowEditModal(false)} open={showEditModal}>
      <Paper
        style={{
          padding: "10px",
          gap: "8px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography component="h3" variant="h6" color="inherit" noWrap>
          Edit Timesheet
        </Typography>
        <BasicDateTimePicker
          label="Time In"
          value={editingTimesheetFields.clockInTime}
          onChange={(clockInTime) =>
            setEditingTimesheetFields({
              ...editingTimesheetFields,
              clockInTime,
            })
          }
        />
        <BasicDateTimePicker
          label="Time Out"
          value={editingTimesheetFields.clockOutTime}
          onChange={(clockOutTime) =>
            setEditingTimesheetFields({
              ...editingTimesheetFields,
              clockOutTime,
            })
          }
        />
        <UnitSelector
          label={"Mileage"}
          value={editingTimesheetFields.mileage}
          onChange={(mileage) =>
            setEditingTimesheetFields({
              ...editingTimesheetFields,
              mileage,
            })
          }
        />
        <UnitSelector
          label={"Break Units"}
          value={editingTimesheetFields.breakUnits}
          onChange={(breakUnits) =>
            setEditingTimesheetFields({
              ...editingTimesheetFields,
              breakUnits,
            })
          }
        />
        <ConfirmDenyButtonAction
          confirmText="Save"
          denyText="Cancel"
          confirmAction={handleEditTimesheetRecord}
          denyAction={() => {
            setShowEditModal(false);
            setSelectedTimesheetId(undefined);
            setEditingTimesheetFields(undefined);
          }}
        />
      </Paper>
    </DefaultModal>
  );
}

export default function TimeSheetTable(props: {
  timesheetRecords: ITimesheetRecord[];
  isLoading?: boolean;
  selectedTherapist: ITherapist;
  tableContainerProps?: CSSProperties;
  start: DateTime;
  end: DateTime;
  setStartDate: (start: DateTime) => void;
  setEndDate: (end: DateTime) => void;
  hideAddButton?: boolean;
  hideExportButton?: boolean;
  style?: CSSProperties;
}): JSX.Element {
  const api = useApi();
  const { triggerRefresh, deadline } = React.useContext(TimesheetContext);
  const { isAdmin } = React.useContext(UserContext);

  const { timesheetRecords, hideAddButton, hideExportButton, style } = props;

  const [showTimesheetForm, setShowTimesheetForm] =
    React.useState<boolean>(false);

  const [selectedTimesheetId, setSelectedTimesheetId] = React.useState<
    string | undefined
  >();

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [editingTimesheetFields, setEditingTimesheetFields] =
    React.useState<EditingTimesheetFields>();

  const filteredRecords = React.useMemo(
    () =>
      timesheetRecords.filter(
        (tsr: ITimesheetRecord) =>
          tsr.therapistId === props.selectedTherapist.userId
      ),
    [props.selectedTherapist.userId, timesheetRecords]
  );

  const rowsForTimesheetTable = React.useMemo(() => {
    return filteredRecords
      .map((record: ITimesheetRecord) => {
        const inDateTime = DateTime.fromISO(record.clockIn);
        const outDateTime = DateTime.fromISO(record.clockOut);
        const breakUnits = parseInt(record.break_units);
        return {
          id: record._id,
          date: inDateTime,
          clockInTime: inDateTime,
          clockOutTime: outDateTime,
          duration: getDuration(inDateTime, outDateTime, breakUnits),
          mileage: record.mileage,
          breakUnits,
          record,
        };
      })
      .filter((ts) => {
        return ts.clockInTime >= props.start && ts.clockOutTime <= props.end;
      });
  }, [filteredRecords, props.end, props.start]);

  const onDelete = React.useCallback(
    (id: string) => {
      api.timesheet
        .delete({ idsArray: [id] })
        .then(
          async (res) =>
            await res
              .json()
              .then((json) => {
                if (res.status === 200) {
                  Toaster("Timesheet Deleted Successfully", ToastType.good);
                  triggerRefresh();
                } else {
                  Toaster(
                    `Could not delete timesheet: ${json.message}`,
                    ToastType.error
                  );
                  logger.error(
                    `ERROR ${res.status} => ${res.statusText}: ${json.message}`,
                    res
                  );
                }
              })
              .catch((e) => logger.error(e))
        )
        .catch((e) => logger.error(e));
    },
    [api.timesheet, triggerRefresh]
  );

  const handleDownloadTimeSheetData = React.useCallback(() => {
    downloadCSV(
      buildTimeSheetCSV(
        filteredRecords
          .filter((ts) => {
            return (
              DateTime.fromISO(ts.clockIn).valueOf() >= props.start.valueOf() &&
              DateTime.fromISO(ts.clockOut).valueOf() <= props.end.valueOf()
            );
          })
          .sort(
            (t1, t2) =>
              DateTime.fromISO(t1.clockIn).valueOf() -
              DateTime.fromISO(t2.clockIn).valueOf()
          ),
        props.selectedTherapist.name
      ),
      `${props.selectedTherapist.name.replaceAll(
        " ",
        "_"
      )}_TimeSheet_Report_${DateTime.local().toLocaleString()}`
    );
  }, [filteredRecords, props.end, props.selectedTherapist.name, props.start]);

  const handleEditTimesheetRecord = React.useCallback(() => {
    if (
      editingTimesheetFields === undefined ||
      selectedTimesheetId === undefined
    )
      return;
    const editObject: UpdateType = {
      id: selectedTimesheetId,
      updateObj: {
        _id: selectedTimesheetId,
        clockIn: formatISO(editingTimesheetFields.clockInTime),
        clockOut: formatISO(editingTimesheetFields.clockOutTime),
        mileage: editingTimesheetFields.mileage.toString(),
        break_units: editingTimesheetFields.breakUnits.toString(),
      } as ITimesheetRecord,
    };
    api.timesheet
      .update(editObject)
      .then(async (res) => {
        if (isContentJSON(res)) {
          await res.json().then((json) => {
            switch (res.status) {
              case 200:
                Toaster("Timesheet Edited Successfully", ToastType.good);
                triggerRefresh();
                break;
              case 423:
                Toaster(
                  "Unable to Edit: Payroll is locked for that range.",
                  ToastType.error
                );
                logger.error(
                  LOGGER_CATEGORY,
                  `ERROR ${res.status} => ${res.statusText}: ${json.message}`
                );
                break;
              case 406:
                Toaster(`${res.statusText} - ${json.message}`, ToastType.error);
                logger.error(
                  LOGGER_CATEGORY,
                  `ERROR ${res.status} => ${res.statusText}: ${json.message}`
                );
                break;
              default:
                Toaster(
                  `${res.statusText} - Unknown Exception: ${json.message}`,
                  ToastType.error
                );
                logger.error(
                  LOGGER_CATEGORY,
                  `ERROR ${res.status} => ${res.statusText}: ${json.message}`
                );
            }
            if (res.status === 200) {
            } else if (res.status === 406) {
            } else {
            }
          });
        } else if (isContentHTML(res)) {
          await res.text().then((t) => {
            Toaster(`Failed to edit timesheet: ${t}`, ToastType.error);
            logger.error(LOGGER_CATEGORY, `ERROR: ${t}`);
          });
        }
      })
      .catch((e) => logger.error(e));
    setEditingTimesheetFields(undefined);
  }, [
    editingTimesheetFields,
    selectedTimesheetId,
    api.timesheet,
    triggerRefresh,
  ]);

  const tableColumns: GridColDef[] = [
    {
      headerName: "Date",
      field: "date",
      flex: 1,
      valueFormatter: ({ row }) =>
        getPeriodDateStringReadable(row.clockInTime, row.clockOutTime),
    },
    {
      headerName: "Time In",
      field: "clockInTime",
      flex: 1,
      valueFormatter: ({ row }) =>
        (row.clockInTime as DateTime).toLocaleString(DateTime.TIME_SIMPLE),
    },
    {
      headerName: "Time Out",
      field: "clockOutTime",
      flex: 1,
      valueFormatter: ({ row }) =>
        (row.clockOutTime as DateTime).toLocaleString(DateTime.TIME_SIMPLE),
    },
    { headerName: "Total (Hours)", field: "duration", flex: 1 },
    { headerName: "Mileage", field: "mileage", flex: 1 },
    { headerName: "Break Units", field: "breakUnits", flex: 1 },
    {
      headerName: "Action",
      field: "record",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => (
        <div>
          <IconButton
            size="small"
            onClick={() => {
              setSelectedTimesheetId(row.record._id);
              setEditingTimesheetFields({
                clockInTime: row.clockInTime,
                clockOutTime: row.clockOutTime,
                mileage: row.mileage,
                breakUnits: row.breakUnits,
              });
              setShowEditModal(true);
            }}
            disabled={!isAdmin && row.clockInTime <= deadline}
          >
            <EditIcon key={"edit"} fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => {
              setSelectedTimesheetId(row.record._id);
              setShowDeleteModal(true);
            }}
            disabled={!isAdmin && row.clockInTime <= deadline}
          >
            <DeleteIcon key={"delete"} fontSize="small" />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <HeaderWithRightSection
        leftSection={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 6,
            }}
          >
            <SectionHeaderText>Timesheets</SectionHeaderText>
            {!hideAddButton && (
              <Button
                variant="outlined"
                size="medium"
                color="primary"
                onClick={() => {
                  setShowTimesheetForm(true);
                }}
              >
                <IconText
                  variant="subtitle2"
                  icon={<TimerOutlinedIcon />}
                  text={"New Timesheet"}
                />
              </Button>
            )}
          </div>
        }
        rightSection={
          <BoldNumberDisplay
            label={"Timesheet"}
            num={
              rowsForTimesheetTable
                .map(
                  (c) =>
                    toInterval(c.clockInTime, c.clockOutTime)
                      .toDuration("hours")
                      .toObject().hours ?? 0
                )
                .reduce((accum, curr) => accum + curr, 0) -
              rowsForTimesheetTable.reduce((p, c) => p + c.breakUnits, 0) * 0.25
            }
            unit={"Hours"}
            variant={"h4"}
            compact
          />
        }
      />
      <DataGrid
        className={useDataGridStyles().className}
        style={style ? style : { height: "40vh" }}
        columns={tableColumns}
        rows={rowsForTimesheetTable}
        loading={props.isLoading}
        components={{
          LoadingOverlay: () => (
            <GridOverlay>
              <CircularProgress size={70} />
            </GridOverlay>
          ),
          Toolbar: () => (
            <GridToolbarContainer>
              <GridToolbarFilterButton />
              {!hideExportButton && rowsForTimesheetTable.length > 0 && (
                <Button
                  style={{ width: "fit-content" }}
                  variant="text"
                  size="small"
                  color="primary"
                  onClick={() => {
                    handleDownloadTimeSheetData();
                  }}
                  fullWidth
                >
                  <IconText
                    // @ts-expect-error this prop can't be null but the formatting is perfect with it ¯\_(ツ)_/¯
                    variant={null}
                    icon={<GetAppSharp />}
                    text={"Export"}
                  />
                </Button>
              )}
            </GridToolbarContainer>
          ),
        }}
        autoHeight={false}
        disableSelectionOnClick
        disableColumnMenu
      />
      <ConfirmationModal
        message={
          <div style={{ display: "flex", alignItems: "center" }}>
            <WarningRoundedIcon
              fontSize="large"
              style={{ marginRight: "10px" }}
            />
            <Typography align="center">
              This operation is <b>irreversible!</b> Are you sure you want to
              delete this time sheet?
            </Typography>
          </div>
        }
        confirmText={"No, don't delete"}
        cancelText={"Yes, delete timsheet"}
        onConfirm={() => {
          setSelectedTimesheetId(undefined);
          setShowDeleteModal(false);
        }}
        onCancel={() => {
          if (selectedTimesheetId) onDelete(selectedTimesheetId);
          setSelectedTimesheetId(undefined);
          setShowDeleteModal(false);
        }}
        open={showDeleteModal}
        setOpen={(isOpen: boolean) => setShowDeleteModal(isOpen)}
      />
      {selectedTimesheetId && editingTimesheetFields && (
        <TimesheetEditModal
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          editingTimesheetFields={editingTimesheetFields}
          setEditingTimesheetFields={setEditingTimesheetFields}
          setSelectedTimesheetId={setSelectedTimesheetId}
          handleEditTimesheetRecord={handleEditTimesheetRecord}
        />
      )}
      <UserTimesheetSubmissionFormModal
        open={showTimesheetForm}
        setOpen={setShowTimesheetForm}
        id={props.selectedTherapist?.userId}
      />
    </div>
  );
}

const LOGGER_CATEGORY = "AdminIndividualTimesheetTable";
