import { Modal, Backdrop, Fade, makeStyles } from "@material-ui/core";
import React from "react";
import { clsx } from "../../clsx";
export interface DefaultModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactElement;
}

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    borderRadius: "10px",
    boxShadow: "",
    padding: "2px 3px 4px",
    width: "33%",
  },
  scrollable: {
    overflow: "auto",
  },
  modalContentArea: {
    maxHeight: "95%",
    overflowY: "auto",
  },
});

export function DefaultModal(props: DefaultModalProps): JSX.Element {
  const styles = useStyles();
  const { open, onClose } = props;
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={styles.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div
          style={{ width: "40vw" }}
          className={clsx(
            styles.paper,
            styles.scrollable,
            styles.modalContentArea
          )}
        >
          {props.children}
        </div>
      </Fade>
    </Modal>
  );
}
