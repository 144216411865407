import React from "react";
import { ITherapist } from "../common/interfaces";
import { SchoolContext } from "../context/SchoolContext";
import { ServicesContext } from "../context/ServicesContext";
import { VisibilityIcon } from "../icons";
import {
  CircularProgress,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@material-ui/data-grid";
import { useDataGridStyles } from "../hooks/useDatagridStyles";

interface IClassificationTableProps {
  onEdit: (name: string) => void;
  onDelete: (name: string) => void;
  onAdd: () => void;
  therapists: ITherapist[];
}

export default function StaffTable(
  props: IClassificationTableProps
): JSX.Element {
  const { schools } = React.useContext(SchoolContext);
  const { classes } = React.useContext(ServicesContext);

  const formatAssignments = React.useCallback(
    (assignments: string[]) => {
      const prettyAssignmentsArray = schools
        .filter((school) => assignments.includes(school.id))
        .map((school) => `${school.name} ${school.invoicePrefix}`);
      const len = prettyAssignmentsArray.length;
      const prettyAssignmentString =
        len > 1 ? `${len} Assignments` : `${prettyAssignmentsArray[0] ?? ""}`;
      return prettyAssignmentString;
    },
    [schools]
  );
  const tableRows = props.therapists.map((therapist) => ({
    id: therapist.userId,
    name: therapist.name,
    schoolIds: therapist.schoolIds,
    service: classes[therapist.classId]?.name || null,
  }));

  const tableColumns: GridColDef[] = [
    {
      headerName: "Name",
      field: "name",
      flex: 1,
    },
    {
      headerName: "Assignments",
      field: "schoolIds",
      flex: 1,
      filterable: false,
      valueGetter: ({ row }) => (row.schoolIds as string[]).length,
      valueFormatter: ({ row }) => formatAssignments(row.schoolIds),
    },
    {
      headerName: "Service",
      field: "service",
      flex: 1,
      valueFormatter: ({ row }) => {
        const className = row.service as string | null;
        if (className === null) {
          return <LinearProgress style={{ width: "100%" }} />;
        } else {
          return className;
        }
      },
    },
    {
      headerName: "Action",
      field: "id",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => (
        <IconButton size="small" onClick={() => props.onEdit(row.id)}>
          <VisibilityIcon key={"view"} fontSize="small" />
        </IconButton>
      ),
    },
  ];

  return (
    <DataGrid
      className={useDataGridStyles().className}
      style={{ height: "81vh" }}
      columns={tableColumns}
      rows={tableRows}
      loading={false}
      components={{
        LoadingOverlay: () => (
          <GridOverlay>
            <CircularProgress size={70} />
          </GridOverlay>
        ),
        Toolbar: () => (
          <GridToolbarContainer>
            <GridToolbarFilterButton />
          </GridToolbarContainer>
        ),
      }}
      autoHeight={false}
      onRowClick={({ id }) => props.onEdit(id as string)}
      disableSelectionOnClick
      disableColumnMenu
    />
  );
}
