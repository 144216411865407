import { IconButton, Paper } from "@material-ui/core";
import React from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ISchool } from "../common/interfaces";
import ConfirmationModal from "../components/ConfirmationModal";
import Toaster, { ToastType } from "../common/Toaster";
import SchoolTable from "../components/SchoolTable";
import { SchoolContext } from "../context/SchoolContext";
import { useApi } from "../api/useApi";
import SideDrawer from "../components/Display/SideDrawer";
import { SchoolEditModalControls } from "../components/SchoolEditModalControl";
import SectionHeaderText from "../components/Display/SectionHeaderText";
import { AddIcon } from "../icons";

export default function DistrictView(): JSX.Element {
  const api = useApi();
  const { makeDirty } = React.useContext(SchoolContext);
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    React.useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = React.useState<boolean>(false);
  const [selectedSchool, setSelectedSchool] = React.useState<
    ISchool | undefined
  >();
  const [schoolToDelete, setSchoolToDelete] = React.useState<ISchool>();

  const clearEditing = React.useCallback(() => {
    setSelectedSchool(undefined);
  }, []);

  const handleEdit = React.useCallback(
    (school: ISchool) => {
      setSelectedSchool(school);
      setEditModalOpen(true);
    },
    [setSelectedSchool, setEditModalOpen]
  );

  const handleExitEdit = React.useCallback(() => {
    clearEditing();
    setEditModalOpen(false);
  }, [clearEditing]);

  const handleSave = React.useCallback(
    (incoming: ISchool) => {
      if (selectedSchool !== undefined && selectedSchool?.id === incoming.id) {
        api.school.update({ id: selectedSchool.id, updateObj: incoming }).then(
          async (res) =>
            await res.json().then((json) => {
              if (res.status === 200) {
                Toaster(`${incoming.name} edited`, ToastType.good);
                makeDirty();
              } else {
                Toaster(
                  `Failed to edit ${incoming.name}: ${json.message}`,
                  ToastType.error
                );
              }
            })
        );
      } else {
        api.school.add(incoming).then(
          async (res) =>
            await res.json().then((json) => {
              if (res.status === 200) {
                Toaster(`${incoming.name} created`, ToastType.good);
                makeDirty();
              } else {
                Toaster(
                  `Failed to create ${incoming.name}: ${json.message}`,
                  ToastType.error
                );
              }
            })
        );
      }

      handleExitEdit();
    },
    [selectedSchool, handleExitEdit, api.school, makeDirty]
  );

  const handleDelete = React.useCallback((school: ISchool) => {
    setSchoolToDelete(school);
    setDeleteConfirmationModalOpen(true);
  }, []);

  const handleDeleteSchool = React.useCallback(() => {
    if (!schoolToDelete) return;
    api.school.delete([schoolToDelete.id]).then(
      async (res) =>
        await res.json().then((j) => {
          if (res.status === 200) {
            Toaster(`${schoolToDelete.name} deleted`, ToastType.good);
            makeDirty();
          } else {
            Toaster(
              `Failed to delete ${schoolToDelete.name}: ${j.message}`,
              ToastType.error
            );
          }
        })
    );
    setDeleteConfirmationModalOpen(false);
  }, [api.school, makeDirty, schoolToDelete]);

  return (
    <Paper style={{ padding: "10px", height: "auto" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <SectionHeaderText>Districts</SectionHeaderText>
        <IconButton
          color="primary"
          onClick={() => {
            setSelectedSchool(undefined);
            setEditModalOpen(true);
          }}
        >
          <AddIcon />
        </IconButton>
      </div>

      <SchoolTable onEdit={handleEdit} onDelete={handleDelete} />

      <ConfirmationModal
        open={deleteConfirmationModalOpen}
        setOpen={setDeleteConfirmationModalOpen}
        onConfirm={() => handleDeleteSchool()}
        onCancel={() => setDeleteConfirmationModalOpen(false)}
        message={`Are you sure want to delete ${schoolToDelete?.name}?`}
      />
      <SideDrawer
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        title={
          selectedSchool ? `Edit ${selectedSchool?.name}` : "Create School"
        }
      >
        <SchoolEditModalControls
          school={selectedSchool}
          handleExit={handleExitEdit}
          handleSave={handleSave}
        />
      </SideDrawer>
    </Paper>
  );
}
