import React from "react";
import { DropdownSelectorOption } from "../../common/interfaces";
import Select, { MultiValue, SingleValue } from "react-select";

export interface DropdownSelectProps {
  value?: DropdownSelectorOption | DropdownSelectorOption[];
  // The current value in the form if it is controlled (if state is being managed by a parent component)
  selectedOption?: DropdownSelectorOption | DropdownSelectorOption[] | null;
  handleChange: (
    selection: readonly DropdownSelectorOption[] | DropdownSelectorOption | null
  ) => void;
  label?: string;
  options: DropdownSelectorOption[];
  className?: string;
  required?: boolean;
  error?: boolean;
  includeAll?: boolean;
  disabled?: boolean;
  placeholder?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  isRtl?: boolean;
  isSearchable?: boolean;
  clearOnSelect?: boolean;
  forcePlaceholder?: boolean;
  noOptionsMessage?: string;
  style?: React.CSSProperties;
}

export function makeDropdownOptions<T>(
  arr: T[],
  labelKey: keyof T,
  valKey: keyof T
): DropdownSelectorOption[] {
  return arr.map((item) => {
    const label = item[labelKey];
    const value = item[valKey];
    if (typeof label !== "string" || typeof value !== "string")
      return { label: "", value: "" };
    return {
      label,
      value,
    };
  });
}

export default function DropdownSelect(
  props: DropdownSelectProps
): JSX.Element {
  const onChange = React.useCallback(
    (
      input:
        | SingleValue<DropdownSelectorOption>
        | MultiValue<DropdownSelectorOption>
    ) => {
      if (props.isMulti) {
        const newValues = input as MultiValue<DropdownSelectorOption>;
        props.handleChange(newValues);
      } else {
        const newValue = input as SingleValue<DropdownSelectorOption>;
        if (newValue) {
          props.handleChange(newValue);
        } else {
          props.handleChange(null);
        }
      }
    },
    [props]
  );

  const styles = {
    menuPortal: (provided: { [key: string]: unknown }) => ({
      ...provided,
      zIndex: 9999,
    }),
    control: (provided: { [key: string]: unknown }) => ({
      ...provided,
      minHeight: "54px",
    }),
  };

  const options = React.useMemo(() => {
    const options = props.options;
    options.sort((a, b) => a.label.localeCompare(b.label));
    return props.includeAll
      ? [{ label: "All", value: "All" } as DropdownSelectorOption, ...options]
      : options;
  }, [props.includeAll, props.options]);

  return (
    <div style={props.style || { width: "100%" }}>
      <Select
        key={
          props.clearOnSelect
            ? `key_${Array.isArray(props.value) ? props.value[0].label : props.value.label}`
            : ""
        }
        value={props.selectedOption}
        defaultValue={(!props.forcePlaceholder && props.value) || undefined}
        styles={styles}
        onChange={(value) => onChange(value)}
        options={options}
        menuPortalTarget={document.body}
        menuPlacement="auto"
        placeholder={props.label}
        isClearable={props.isClearable}
        isDisabled={props.isDisabled}
        isLoading={props.isLoading}
        isMulti={props.isMulti}
        isRtl={props.isRtl}
        isSearchable={props.isSearchable}
        noOptionsMessage={
          props.noOptionsMessage ? () => props.noOptionsMessage : undefined
        }
      />
    </div>
  );
}
